import React from 'react';

const ThreeCardGrid = ({ gridData }) => {
    return (
        <div className="grid grid-cols-1 gap-4 h-auto lg:grid-cols-3 lg:h-96">
            <div className="grid grid-cols-1 gap-4 lg:grid-rows-2 lg:col-span-1">
                {gridData.slice(0, 2).map((data, index) => (
                    <div
                        key={index}
                        className="relative bg-cover bg-center rounded-lg overflow-hidden h-64 lg:h-auto"
                        style={{ backgroundImage: `url(${data.image})` }}
                    >
                        <div className="absolute inset-0 bg-black bg-opacity-40 flex flex-col justify-end p-4">
                            <p className="!text-white">{data.text1}</p>
                            <h3 className="text-white text-xl font-bold">{data.text2}</h3>
                        </div>
                    </div>
                ))}
            </div>
            <div
                className="relative bg-cover bg-center rounded-lg overflow-hidden h-64 lg:h-auto lg:col-span-2"
                style={{ backgroundImage: `url(${gridData[2].image})` }}
            >
                <div className="absolute inset-0 bg-black bg-opacity-40 flex flex-col justify-end p-4">
                    <h3 className="text-white text-xl font-bold">{gridData[2].text1}</h3>
                    <p className="!text-white text-3xl font-bold">{gridData[2].text2}</p>
                </div>
            </div>
        </div>
    );
};

export default ThreeCardGrid;
