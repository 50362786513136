import React, { useState } from 'react';

const StarRating = ({ initialRating = 0, onChange }) => {
  const [rating, setRating] = useState(initialRating);

  const handleClick = (star) => {
    setRating(star);
    if (onChange) {
      onChange(star); // Notify parent component of the change
    }
  };

  return (
    <div className="flex items-center">
      {Array.from({ length: 5 }, (_, index) => (
        <svg
          key={index}
          onClick={() => handleClick(index + 1)}
          className={`w-6 h-6 cursor-pointer ${index < rating ? 'text-yellow-400' : 'text-gray-300'}`}
          fill="currentColor"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M12 17.27L18.18 21l-1.64-7.03L22 9.24l-7.19-.62L12 2 9.19 8.62 2 9.24l5.46 4.73L5.82 21z" />
        </svg>
      ))}
    </div>
  );
};

export default StarRating;
