import React, { useState } from "react";
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import { useDispatch, useSelector } from "react-redux";
import { setTemplate } from "../../../redux/reducers/template/templateSlice";

const ContactLayoutOption = ({ onSave, onBack }) => {
  const layouts = [
    { value: 1, label: "Layout 1" },
    { value: 2, label: "Layout 2" },
    { value: 3, label: "Layout 3" },
    { value: 4, label: "Layout 4" },
  ];
  
  const state = useSelector(state => state.template);
  const [selected, setSelected] = useState(Number(state.contactLayout || 1));
  const dispatch = useDispatch();

  function handleSelect(obj) {
    setSelected(obj.value);
    dispatch(setTemplate({ ...state, contactLayout: obj.value }));
  }

  return (
    <div className="mt-10 px-10">
      <div>
        <h3 className="font-BrFirmaRegular mt-6 text-2xl">Choose a site layout</h3>
        <p className="font-BrFirmaRegular my-2">
          Select a website layout that matches your business and customize accordingly
        </p>
      </div>
      <div className="mt-10 flex items-center gap-5">
        <div className="bg-[#d2d2d2] p-2 text-white rounded-full text-xl flex justify-center items-center cursor-pointer">
          <NavigateBeforeIcon />
        </div>
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-5 gap-8">
          {layouts.map((obj, i) => (
            <div 
              key={i} 
              className={`col-span-1 max-w-44 rounded-lg p-2 flex justify-center items-center cursor-pointer ${selected === obj.value ? 'bg-theme1':'bg-[#D9D9D9]'}`}
              onClick={() => handleSelect(obj)}
            >
              <div className="w-44 h-44 bg-gray-300 flex items-center justify-center text-gray-500">
                {obj.label}
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className="mt-5 flex justify-between">
        <button 
          onClick={onSave} 
          className="bg-blue-500 text-white px-4 py-2 rounded-md hover:bg-blue-600 transition duration-300"
        >
          Save
        </button>
        <button 
          onClick={onBack} 
          className="bg-gray-500 text-white px-4 py-2 rounded-md hover:bg-gray-600 transition duration-300"
        >
          Back
        </button>
      </div>
    </div>
  );
};

export default ContactLayoutOption;
