import { useState } from 'react';
import { Icon } from '@iconify/react';
import Logo from '../../Logo/Logo';
import { getsubDomain } from '../../../utils/getsubDomain';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';

const Hero1 = ({overrideImage, title, description}) => {
  const state = useSelector(state => state.template);
  const {userData: {user,loggedIn}} = useSelector(state => state.user);
  // const [activeLink, setActiveLink] = useState('flight');

  const dispatch = useDispatch();
  

  return (
    <div className="relative max-w-7xl mx-auto rounded-lg overflow-hidden shadow-lg my-8">
    <div
      className="relative h-96 md:h-[500px] w-full bg-cover bg-center flex items-center"
      style={{
        backgroundImage: `url(${overrideImage })`,
      }}
    >
      <div className="absolute inset-0 bg-black opacity-50"></div>
      <div className="relative z-10 p-8 bg-white rounded-lg shadow-lg max-w-md flex flex-col justify-center lg:ml-6 sm:ml-2">
        <div className="uppercase tracking-wide text-sm text-blue-400 font-semibold">
          {title}
        </div>
        <h1 className="mt-1 text-3xl md:text-4xl font-bold leading-tight text-gray-900">
          {description}
        </h1>
        <p className="mt-2 text-gray-600">
          Hear from our satisfied customers
        </p>
      </div>
    </div>
  </div>
  );
}

export default Hero1;
