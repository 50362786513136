import React, { useState } from "react";
import EmailInput from "../form/EmailInput";
import PasswordInput from "../form/PasswordInput";
import Button1 from "../form/Button1";
import { Link, useNavigate } from "react-router-dom";
import { useSnackbar } from "notistack";
import { loginReqData } from "../../data/user/Auth/loginReq";
import login from "../../controllers/Auth/login";
import { useDispatch, useSelector } from "react-redux";
import { setUserData } from "../../redux/reducers/userSlice";
import Icon from "../HOC/Icon";
import Logo from "../Logo/Logo";
import staffLogin from "../../controllers/Auth/staff/login";
import { getsubDomain } from "../../utils/getsubDomain";

export default function Login() {
  const [data, setData] = useState({ ...loginReqData, confirmPassword: "" });
  const [loading, setLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {userData: {agent}} = useSelector(state => state?.user)

  async function handleSubmit(ev) {
    ev.preventDefault();

    setLoading(true);
    let res = {return: 0,msg: 'Something went wrong on our end! Please contact support. 0xSTLOG'}
    if(agent && (agent.email !== data.email))
      res = await staffLogin({...data,agentId: agent?._id});
    else
      res = await login(data);
    setLoading(false);
    if (res.return) {
      // enqueueSnackbar("You are logged in.", { variant: "success" });
      if(res?.data?.account?.userType === 'Admin')
        return enqueueSnackbar('Admin is not allowed to login on this domain',{variant: 'error'});
      dispatch(
        setUserData({
          loggedIn: true,
          accessToken: res?.data?.token,
          user: res?.data?.account,
          agent
        }),
      );
    } else {
      enqueueSnackbar(res?.msg || "Invalid Credentials.", { variant: "error" });
      if (res?.data?.detail?.accountStatus === "Inactive")
        navigate("/?view=verify&email=" + data.email);
    }
  }

  async function handleGoogleAuth() {
    let curUrl = new URLSearchParams({ callbackUrl: window.location.href });
    window.location.href =
      process.env.REACT_APP_API + "/main/v1/auth/google?" + curUrl;
  }

  return (
    <div className="flex flex-col min-h-screen font-bold gap-4">
      <div className="w-full p-3 px-5 flex gap-2 bg-secondary justify-center sm:justify-start">
        <Logo />
      </div>
      <form
        onSubmit={handleSubmit}
        className="w-full flex flex-col items-center justify-center flex-1"
      >
        <div className="card flex flex-col gap-5 text-center !pt-12">
          <h4 className="pb-6">Sign in to your account</h4>
          <EmailInput
            required
            className="!w-full sm:!w-[400px] max-w-full"
            value={data.email}
            placeholder=""
            onChange={(ev) => setData({ ...data, email: ev.target.value })}
          />
          <PasswordInput
            required
            noValidation
            placeholder=""
            className="!w-full sm:!w-[400px] max-w-full"
            value={data.password}
            onChange={(ev) => setData({ ...data, password: ev.target.value })}
          />
          <Button1 loading={loading} type="submit" label={"Login"}></Button1>
          <div className="self-center text-center flex flex-col gap-1 ">
            <Link to="?view=reset" className="self-center text-primary/60">
              Forgot your password?{" "}
              <span className="text-theme1">Recover now</span>
            </Link>


            {!getsubDomain() ?
              <div className="flex flex-wrap gap-2 items-center justify-center">
                <p className="text-primary/40">Dont have an account?</p>
                <Link className="text-theme1 font-bold" to="?view=register">
                  Sign up
                </Link>
              </div>
            :null}
          </div>
        </div>
      </form>
    </div>
  );
}
