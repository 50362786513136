import React, { useEffect, useRef, useState } from 'react';
import { Button } from '@mui/material';
import { useSelector } from 'react-redux';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { enqueueSnackbar } from 'notistack';
import Header from '../../../components/templates/Header';
import updateProfile from '../../../controllers/user/updateProfile';
import Button1 from '../../../components/form/Button1';
import ChoseTemplate from './choseTemplate';
import GenerateLinkHome from './generateLinkHome';
import Preview from '../../../components/templates/preview/Preview';
import HeaderStyle from './headerStyle';
import FooterStyle from './footerStyle';
import SearchBarStyle from './searchBarStyle';
import ApplyPage from './applyPage';
import AboutLayout from './AboutLayout';
import ContactLayout from './ContactLayout';
import ChooseProducts from './ChooseProduct.jsx';
import GeneralPayment from './generalPayment/index.jsx';
import ConfirmationPage from './confirmationPage/index.jsx';
import HeroStyle from './heroStyle/index.jsx';

const components = [
    <ChoseTemplate />,
    <ChooseProducts />,
    <GenerateLinkHome />,
    <GeneralPayment />,
    <ApplyPage />,
    <HeaderStyle />,
    <HeroStyle />,
    <SearchBarStyle />,
    <FooterStyle />,
    <AboutLayout />,
    <ContactLayout />,
];

const GenerateLink = () => {
    const state = useSelector((state) => state.template);
    const location = useLocation();
    const navigate = useNavigate();
    const searchParams = new URLSearchParams(location.search);
    const step = parseInt(searchParams.get('step')) || 0; // Get 'step' from the query string or default to 0
    const [currentForm, setCurrentForm] = useState(step); // Initialize state from URL
    const [fullScreen, setFullScreen] = useState(false);
    const iframeRef = useRef(null);
    const [scale, setScale] = useState(0.4);
    const iframeRefScale = useRef(null);
    const [loading, setLoading] = useState(false);

    const updateCurrentForm = (action) => {
        let newForm = currentForm;
        if (action === 'next') {
            newForm = currentForm < components.length - 1 ? currentForm + 1 : currentForm;
        } else if (action === 'back') {
            newForm = currentForm > 0 ? currentForm - 1 : currentForm;
        }

        setCurrentForm(newForm);
        navigate(`?step=${newForm}`); // Update URL when form changes
    };

    let currentHost = window.location.protocol + '//' + window.location.host;

    const updateScale = () => {
        let screenWidth = window.innerWidth;
        if (iframeRefScale.current) {
            setScale(iframeRefScale.current.offsetWidth / screenWidth);
        }
    };

    useEffect(() => {
        window.addEventListener('resize', updateScale);
        updateScale();
        return () => {
            window.removeEventListener('resize', updateScale);
        };
    }, []);

    const handleFullScreen = () => {
        window.open(currentHost + '/preview', '_blank');
    };

    async function save() {
        setLoading(true);
        const res = await updateProfile({ templateData: JSON.stringify(state) });
        setLoading(false);
        if (res.return) {
            enqueueSnackbar('Saved', { variant: 'success' });
        } else enqueueSnackbar(res.msg, { variant: 'error' });
    }

    return (
        <div>
            <Header />
            <div className={`grid grid-cols-1 xl:grid-cols-5 gap-2 h-full px-4`}>
                <div className="col-span-3">
                    {components[currentForm]}
                    <div className="flex justify-between place-items-end mx-10 my-10">
                        {currentForm > 0 ? (
                            <Button variant="text" onClick={() => updateCurrentForm('back')} sx={{ textTransform: 'none' }}>
                                Go back
                            </Button>
                        ) : (
                            <div>
                                <Button1 variant="text" loading={loading} onClick={() => save()} sx={{ textTransform: 'none' }}>
                                    Save and Exit
                                </Button1>
                            </div>
                        )}
                        {currentForm < components.length - 1 ? (
                            <Button onClick={() => updateCurrentForm('next')} sx={{ textTransform: 'none' }}>
                                Next
                            </Button>
                        ) : (
                            <div>
                                <Button1 variant="text" loading={loading} onClick={() => save()} sx={{ textTransform: 'none' }}>
                                    Save
                                </Button1>
                            </div>
                        )}
                    </div>
                </div>
                <div ref={iframeRefScale} className="col-span-2">
                    <div className="shadow-md h-full">
                        <div className="w-full flex justify-center mt-4 mb-4">
                            <Link to="/order/new/flight/template/1" target="_blank">
                                <Button variant="outlined" className="bg-silverColor" sx={{ textTransform: 'none' }}>
                                    Full screen preview
                                </Button>
                            </Link>
                        </div>
                        <div className="origin-top-left h-full">
                            <iframe
                                ref={iframeRef}
                                src={currentHost + '/order/new/flight/template/1'}
                                className={`w-screen overflow-hidden border-0 origin-top-left`}
                                style={{ height: `${100 / scale}%`, transform: `scale(${scale})` }}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default GenerateLink;
