import React from 'react';

const TestimonialCard = ({ image, name, review, title }) => {
  return (
    <div className=" bg-gray-100 p-8 rounded-2xl  max-w-sm mx-auto">
      <div className="flex items-center">
        <img src={image} alt={name} className="w-12 h-12 rounded-full mr-4" />
        <div>
          <h3 className="text-lg font-semibold">{name}</h3>
          <div className="flex items-center">
            {/* Display the star ratings */}
            {[...Array(5)].map((_, index) => (
              <svg
                key={index}
                xmlns="http://www.w3.org/2000/svg"
                className="h-4 w-4 text-yellow-500"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.286 3.96a1 1 0 00.95.69h4.173c.969 0 1.371 1.24.588 1.81l-3.385 2.463a1 1 0 00-.364 1.118l1.287 3.961c.3.92-.755 1.688-1.539 1.118l-3.385-2.462a1 1 0 00-1.176 0l-3.385 2.462c-.783.57-1.838-.198-1.539-1.118l1.286-3.961a1 1 0 00-.364-1.118L2.26 9.387c-.783-.57-.38-1.81.588-1.81h4.173a1 1 0 00.95-.69l1.286-3.96z" />
              </svg>
            ))}
          </div>
        </div>
      </div>
      <div className="mt-4">
        <h4 className="text-sm font-bold">{title}</h4>
        <p className="text-gray-600 text-sm mt-2">{review}</p>
      </div>
    </div>
  );
};

export default TestimonialCard;
