import { useSelector } from "react-redux"
import Header1 from "./Header1"
import Header2 from "./Header2"
import Header3 from "./Header3"
import Header4 from "./Header4"
import Header5 from "./Header5"
import Header6 from "./Header6"
import Header7 from "./Header7"
import Header8 from "./Header8"
import Header9 from "./Header9"
import Header11 from "./header11"

const HeadersList = ({id,overrideImage}) => {
    const state = useSelector((state)=> state.template);  
    switch (id || state.headerStyle){
        case "1":
            return <Header11 state={state} overrideImage={overrideImage} logo={state.logoImage ? state.logoImage : undefined} primaryColor={state.primaryColor} secondaryColor={state.secondaryColor}/>
        case "2":
            return <Header2  overrideImage={overrideImage} logo={state.logoImage ? state.logoImage : undefined} primaryColor={state.primaryColor} secondaryColor={state.secondaryColor}/>
        case "3":
            return <Header3  overrideImage={overrideImage} logo={state.logoImage ? state.logoImage : undefined} primaryColor={state.primaryColor} secondaryColor={state.secondaryColor}/>
        case "4":
            return <Header4  overrideImage={overrideImage} logo={state.logoImage ? state.logoImage : undefined} primaryColor={state.primaryColor} secondaryColor={state.secondaryColor}/>
        case "5":
            return <Header5  overrideImage={overrideImage} logo={state.logoImage ? state.logoImage : undefined} primaryColor={state.primaryColor} secondaryColor={state.secondaryColor}/>
        case "6":
            return <Header6  overrideImage={overrideImage} logo={state.logoImage ? state.logoImage : undefined} primaryColor={state.primaryColor} secondaryColor={state.secondaryColor}/>
        case "7":
            return <Header7  overrideImage={overrideImage} logo={state.logoImage ? state.logoImage : undefined} primaryColor={state.primaryColor} secondaryColor={state.secondaryColor}/>
        case "8":
            return <Header8  overrideImage={overrideImage} logo={state.logoImage ? state.logoImage : undefined} primaryColor={state.primaryColor} secondaryColor={state.secondaryColor}/>
        case "9":
            return <Header9  overrideImage={overrideImage} logo={state.logoImage ? state.logoImage : undefined} primaryColor={state.primaryColor} secondaryColor={state.secondaryColor}/>
        default:
            return <Header1  state={state} overrideImage={overrideImage} logo={state.logoImage ? state.logoImage : undefined} primaryColor={state.primaryColor} secondaryColor={state.secondaryColor}/>
    }
}

export default HeadersList