import React, { createContext, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { crmData } from '../../../../data/customer/CRM';
import Icon from '../../../HOC/Icon';
import Modal1 from '../../../DIsplay/Modal/Modal1';
import Button1 from '../../../form/Button1';
import TextInput from '../../../form/TextInput';
import updateProfile from '../../../../controllers/user/updateProfile';
import { setUser } from '../../../../redux/reducers/userSlice';
import { enqueueSnackbar } from 'notistack';
import axios from 'axios';
import { clone } from '../../../../features/utils/objClone';
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

const ModalContext = createContext(null);

var cancelTokenSource = null;

export default function PrivacyPolicyCRM() {
    const { user } = useSelector(state => state.user.userData);
    
    let { privacyPolicy = { heroText: null, policies: [] } } = clone(user?.detail?.crmData || crmData);
    
    const defData = clone({ privacyPolicy });
    const [data, setData] = useState(defData);
    const [modalOpen, setModalOpen] = useState({ hero: false, heroImage: false, privacyPolicy: false });
    const [loading, setLoading] = useState({ submit: false });
    const dispatch = useDispatch();

    async function handleUpload(ev, newData) {
        ev?.preventDefault();
        if (cancelTokenSource) cancelTokenSource.cancel('Canceled for another request');
        cancelTokenSource = axios.CancelToken.source();

        setLoading({ ...loading, submit: true });
        const res = await updateProfile(
            { crmData: { ...user?.detail?.crmData, ...(newData || data) } },
            cancelTokenSource?.token
        );
        setLoading({ ...loading, submit: false });

        if (res.return) {
            enqueueSnackbar('Page Updated', { variant: 'success' });
            dispatch(setUser(res.data));
        } else {
            enqueueSnackbar(res.msg, { variant: 'error' });
        }
    }

    const EditModal = ({ children, title, data: defData, ...props }) => {
        const [value, setValue] = useState(defData || '');

        function handleSubmit() {
            const updData = { ...data, ...value };
            setData(updData);
            handleUpload(null, updData);
            props?.setOpen && props?.setOpen(false);
        }

        return (
            <Modal1 {...props}>
                <div className="p-5 flex flex-col gap-4">
                    <div className="flex gap-4 justify-between py-4">
                        <b>{title}</b>
                        <Icon icon="mdi:close" onClick={() => props?.setOpen()} />
                    </div>
                    <ModalContext.Provider value={{ value, setValue }}>
                        {children}
                    </ModalContext.Provider>
                </div>
            </Modal1>
        );
    };

    const handlePolicyChange = (newPolicy) => {
        setData((prevData) => {
            const updatedPolicies = [...prevData.privacyPolicy.policies, newPolicy];
            const newData = {
                ...prevData,
                privacyPolicy: {
                    ...prevData.privacyPolicy,
                    policies: updatedPolicies,
                },
            };
            handleUpload(null, newData);
            return newData;
        });
    };
    
    const handleDeletePolicy = (index) => {
        setData((prevData) => {
            const updatedPolicies = prevData.privacyPolicy.policies.filter((_, i) => i !== index);
            const newData = {
                ...prevData,
                privacyPolicy: {
                    ...prevData.privacyPolicy,
                    policies: updatedPolicies,
                },
            };
            handleUpload(null, newData);
            return newData;
        });
    };
    

    const sections = [
        {
            title: 'Hero Section',
            section: [
                {
                    label: 'Hero Text',
                    body: data.privacyPolicy?.heroText,
                    action: (section) => (
                        <div>
                            <button
                                onClick={() => setModalOpen({ ...modalOpen, hero: true })}
                                className="text-xs flex gap-2 bg-primary/5 p-2 px-4"
                            >
                                Change <Icon icon="tabler:edit" className="!w-4 !h-4" />
                            </button>
                            <EditModal
                                open={modalOpen.hero}
                                setOpen={() => setModalOpen({ ...modalOpen, hero: false })}
                                title={section.title}
                            >
                                <ModalContext.Consumer>
                                    {({ value, setValue }) => (
                                        <div>
                                            <TextInput
                                                label="Hero Text"
                                                value={value.privacyPolicy?.heroText || ''}
                                                onChange={(ev) => setValue({
                                                    ...value,
                                                    privacyPolicy: {
                                                        ...value.privacyPolicy,
                                                        heroText: ev.target.value
                                                    }
                                                })}
                                            />
                                        </div>
                                    )}
                                </ModalContext.Consumer>
                            </EditModal>
                        </div>
                    )
                }
            ]
        },
        {
            title: 'Privacy Policy',
            section: [
                {
                    label: 'Policy',
                    body: (
                        <div>
                            <ul>
                            {
                                data?.privacyPolicy?.policies?.map((policy, index) => (
                                    <li key={index} className="py-2 flex justify-between items-center">
                                        <div dangerouslySetInnerHTML={{ __html: policy }} />
                                        <button
                                            className="text-xs bg-red-500 text-white p-1 px-2 rounded"
                                            onClick={() => handleDeletePolicy(index)}
                                        >
                                            Delete
                                        </button>
                                    </li>
                                )) || <p>No policies available</p>
                            }
                            </ul>
                        </div>
                    ),
                    action: (section) => (
                        <div>
                            <button
                                onClick={() => setModalOpen({ ...modalOpen, privacyPolicy: true })}
                                className="text-xs flex gap-2 bg-primary/5 p-2 px-4"
                            >
                                Add <Icon icon="material-symbols:add" className="!w-4 !h-4" />
                            </button>
                            <EditModal
                                open={modalOpen.privacyPolicy}
                                setOpen={() => setModalOpen({ ...modalOpen, privacyPolicy: false })}
                                title="Add Privacy Policy"
                            >
                                <ModalContext.Consumer>
                                    {({ value, setValue }) => (
                                        <div className="flex flex-col gap-4 w-[500px]">
                                            <ReactQuill
                                                value={value}
                                                onChange={(content) => setValue(content)}
                                                theme="snow"
                                                modules={{
                                                    toolbar: [
                                                        [{ header: [1, 2, false] }],
                                                        ["bold", "italic", "underline", "strike", "blockquote"],
                                                        [{ list: "ordered" }, { list: "bullet" }, { indent: "-1" }, { indent: "+1" }],
                                                        ["link", "image"],
                                                        ["clean"],
                                                    ],
                                                }}
                                            />
                                            <Button1
                                                onClick={() => {
                                                    handlePolicyChange(value);
                                                    setValue(''); // Clear the input after adding
                                                }}
                                            >
                                                Add Policy
                                            </Button1>
                                        </div>
                                    )}
                                </ModalContext.Consumer>
                            </EditModal>
                        </div>
                    )
                }
            ]
        }
    ];

    let currentHost = window.location.protocol + "//" + window.location.host;
    const iframeRefScale = useRef();
    const [scale, setScale] = useState(0.3);

    const updateScale = () => {
        let screenWidth = window.innerWidth;
        if (iframeRefScale.current) {
            setScale((iframeRefScale.current.offsetWidth / screenWidth));
        }
    };

    useEffect(() => {
        window.addEventListener('resize', updateScale);
        updateScale();
        return () => {
            window.removeEventListener('resize', updateScale);
        };
    }, []);

    return (
        <div className="pd-md flex gap-4 flex-wrap md:flex-nowrap overflow-hidden">
            <div className="md:w-[65%]">
                <div className="pb-4">
                    <h3>Edit Privacy Policy page content</h3>
                    <p>These logo, colors, and font will be used to design your site. You can always change them later.</p>
                </div>
                <div className="flex flex-col gap-4">
                    {sections?.map((section, i) => (
                        <div key={section?.title + i}>
                            <div className="bg-theme1/10 p-4 py-2">{section?.title}</div>
                            {section?.section?.map((obj, j) => (
                                <div key={section?.title + obj?.label + j} className="flex gap-4 p-4 border-b">
                                    <div className="w-[150px]">{obj?.label}</div>
                                    <div className="flex-1 max-w-[64%] overflow-hidden">{obj?.body}</div>
                                    <div>{obj?.action(section)}</div>
                                </div>
                            ))}
                        </div>
                    ))}
                </div>
            </div>
            <div className="origin-top-left md:w-[30%]">
                <div className="flex justify-center mb-6">
                    <button
                        className="text-xs flex gap-2 bg-primary/5 p-2 px-4"
                        onClick={() =>
                            window.open(
                                currentHost + "/preview/template/privacypolicy",
                                "_blank"
                            )
                        }
                    >
                        Preview
                    </button>
                </div>
                <iframe
                    src={currentHost + "/preview/template/privacypolicy"}
                    className="w-screen origin-top-left overflow-hidden min-h-[70vh]"
                    style={{ height: `${100 / scale}%`, transform: `scale(${scale})` }}
                />
            </div>
        
        </div>
    );
}
