import React, { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { isCustomerSite } from '../../../utils/getsubDomain';
import FooterList from '../footers/footerList';
import HeroList from '../heroStyles/heroList';

const PrivacyPolicy = () => {
  const { user, agent } = useSelector((state) => state.user.userData);
  const crmData = (agent || user)?.detail?.crmData;

  console.log('crmData:', crmData);

  const customer = isCustomerSite();
  const ref = useRef();

  useEffect(() => {
    ref.current?.scrollIntoView({ behavior: 'smooth' });
  }, []);

  const dummyPrivacyContent = [
    {
      content: 'We collect personal information such as your name, email address, and usage data to provide and improve our services.',
    },
    {
      content: 'Your information is used to provide our services, communicate with you, and ensure the security of our platform.',
    },
    {
      content: 'We implement various security measures to protect your personal information from unauthorized access or disclosure.',
    },
  ];

  if (!crmData) {
    return <div>Loading...</div>;
  }

  const { privacyPolicy } = crmData;

  // Fallback image if privacyPolicy?.heroImage is null or undefined
  const defaultImage = 'https://www.cookieyes.com/wp-content/uploads/2022/05/Privacy-policy-01-1.png';
  const heroImage = privacyPolicy?.heroImage || defaultImage;

  return (
    <div ref={ref} className="mx-auto ">
      {/* HeroList component with fallback image */}
      <HeroList title="Privacy Policy" overrideImage={heroImage} />
      <div className="flex flex-col items-center">
        <div className="pd-md max-w-full pb-10 w-full">

          {privacyPolicy?.policies && privacyPolicy.policies.length > 0 ? (
            privacyPolicy.policies.map((policy, index) => (
              <div key={index} className="mb-8">
                {/* Render the HTML content of each policy */}
                <div dangerouslySetInnerHTML={{ __html: policy }} className="text-gray-700" />
              </div>
            ))
          ) : (
            <div className="mt-10">
              <h2 className="text-2xl font-bold mb-6">Privacy Policy Information</h2>
              <ul>
                {dummyPrivacyContent.map((section, index) => (
                  <li key={index} className="mb-6">
                    <p className="text-gray-700">{section.content}</p>
                  </li>
                ))}
              </ul>
            </div>
          )}
        </div>
      </div>
      <FooterList />
    </div>
  );
};

export default PrivacyPolicy;
