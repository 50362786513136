import React, { useState } from "react";
import layout1 from '../../../../assets/images/layouts/layout1.png';
import layout2 from '../../../../assets/images/layouts/layout2.png';
import layout3 from '../../../../assets/images/layouts/layout3.png';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import { useDispatch, useSelector } from "react-redux";
import { setTemplate } from "../../../../redux/reducers/template/templateSlice";

const ChoseTemplate = () => {
  const layouts = [
    {img: layout1, value: 1},
    {img: layout2, value: 2},
    {img: layout3, value: 3},
    {value: 4}, // No image for layout 4
    {value: 5}, // No image for layout 5
  ];
  
  const state = useSelector(state => state.template);
  const [selected, setSelected] = useState(Number(state.layoutType || 1));
  const dispatch = useDispatch();

  console.log('layout: ', state?.layoutType);

  function handleSelect(obj) {
    setSelected(obj.value);
    dispatch(setTemplate({...state, layoutType: obj.value}));
  }

  return (
    <div className="mt-10 px-10">
      <div>
        <h3 className="font-BrFirmaRegular mt-6 text-2xl">Choose a site layout</h3>
        <p className="font-BrFirmaRegular my-2">
          Select a website layout that matches your business and customize accordingly
        </p>
      </div>
      <div className="mt-10 flex items-center gap-5">
        <div className="bg-[#d2d2d2] p-2 text-white rounded-full text-xl flex justify-center items-center cursor-pointer">
          <NavigateBeforeIcon />
        </div>
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-5 gap-8">
          {layouts?.map((obj, i) => (
            <div 
              key={i} 
              className={`col-span-1 max-w-44 rounded-lg p-2 flex justify-center items-center cursor-pointer ${selected === obj?.value ? 'bg-theme1':'bg-[#D9D9D9]'}`}
              onClick={() => handleSelect(obj)}
            >
              {obj.img ? (
                <img src={obj.img} alt={`Layout ${obj.value}`} className="w-44 h-44 object-cover"/>
              ) : (
                <div className="w-44 h-44 bg-gray-300 flex items-center justify-center text-gray-500">
                  Layout {obj.value}
                </div>
              )}
            </div>
          ))}
        </div>
        <div className="bg-[#d2d2d2] p-2 text-white rounded-full text-xl flex justify-center items-center cursor-pointer">
          <NavigateNextIcon />
        </div>
      </div>
    </div>
  );
};

export default ChoseTemplate;