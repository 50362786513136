import React from 'react'
import Contact from './Layout/Contact';
import { useSelector } from 'react-redux';
import Contact2 from './Layout/Contact2';
import Contact3 from './Layout/Contact3';
import Contact4 from './Layout/Contact4';

export default function ContactList({id,noScroll}) {
    const state = useSelector((state)=> state.template);  
    
    switch (id || state.contactLayout) {
        case 1:
            return <Contact4 noScroll />
        case 2:
            return <Contact2 noScroll />
        case 3:
            return <Contact3 noScroll />
        default:
            return <Contact noScroll />
    }
}
