import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setPaymentMethods } from '../../../../redux/reducers/template/templateSlice';
import { Input } from '@mui/material';

const GeneralPayment = () => {
  const activePaymentMethods = useSelector((state) => state.template?.paymentMethods || []);
  const dispatch = useDispatch();

  const paymentMethods = [
    { id: 'credit_debit', name: 'Instant card payment', description: 'Accept credit and debit card payments using your payment gateway' },
    { id: 'bank_transfer', name: 'Bank transfer', description: 'Accept bank transfer payments' },
    { id: 'book_on_hold', name: 'Book on hold', description: 'Allow customers to book and pay later' },
    { id: 'all', name: 'All', description: 'Accept all payment methods' }
  ];

  const handlePaymentMethodToggle = (methodId) => {
    let newActivePaymentMethods;
    if (methodId === 'all') {
      newActivePaymentMethods = activePaymentMethods.includes('all') ? [] : ['all'];
    } else {
      if (activePaymentMethods.includes('all')) {
        newActivePaymentMethods = [methodId];
      } else {
        newActivePaymentMethods = activePaymentMethods.includes(methodId)
          ? activePaymentMethods.filter(id => id !== methodId)
          : [...activePaymentMethods, methodId];
      }
      if (newActivePaymentMethods.length === paymentMethods.length - 1) {
        newActivePaymentMethods = ['all'];
      }
    }
    dispatch(setPaymentMethods(newActivePaymentMethods));
  };

  const isPaymentMethodActive = (methodId) => activePaymentMethods.includes(methodId);

  return (
    <div className=" mx-auto p-6 w-auto rounded-lg">
      <h1 className="font-BrFirmaRegular text-2xl mb-4">General settings</h1>
      <p className="text-gray-600 mb-4">Select the payment methods and chat settings. You can always change them later.</p>
      
      <h2 className="text-lg font-semibold mb-4">Payment methods</h2>
      <p className="text-gray-600 mb-4">Select your preferred payment methods.</p>
      
      <div className="space-y-3 mb-4">
        {paymentMethods.map((method) => (
          <div 
            key={method.id}
            className={`p-4 rounded-lg cursor-pointer transition-colors ${
              isPaymentMethodActive(method.id)
                ? 'bg-blue-100 border border-blue-500' 
                : 'bg-gray-100 hover:bg-gray-200'
            }`}
            onClick={() => handlePaymentMethodToggle(method.id)}
          >
            <div className="flex items-center">
              <div className={`w-5 h-5 mr-2 border rounded flex items-center justify-center ${
                isPaymentMethodActive(method.id) ? 'bg-blue-500 border-blue-500' : 'border-gray-400'
              }`}>
                {isPaymentMethodActive(method.id) && (
                  <svg className="w-3 h-3 text-white" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path>
                  </svg>
                )}
              </div>
              <span className={`font-medium ${isPaymentMethodActive(method.id) ? 'text-blue-700' : 'text-gray-700'}`}>
                {method.name}
              </span>
            </div>
            <p className={`mt-1 text-sm ${isPaymentMethodActive(method.id) ? 'text-blue-600' : 'text-gray-500'}`}>
              {method.description}
            </p>
          </div>
        ))}
      </div>
      
      <h2 className="text-lg font-semibold mb-4">Contact whatsapp number</h2>
      {/* <p className="text-gray-600 mb-4">Configure your chat settings to serve your customers right.</p> //TODO: WHATSAPP PHONE NUMBER FIELD */}
      
      <div className='rounded-lg bg-gray-100 p-4'>
        <Input type='number' name='whatsapp-phonenumber' placeholder='Whatsapp number' className='m-auto'/>
      </div>
      
    </div>
  );
};

export default GeneralPayment;