import { useSelector } from "react-redux";
import SearchBarList from "../Searchbars/searchBarList";
import FooterList from "../footers/footerList";
import HomeCarousel from "../layouts/HomeCarousel";
import HeadersList from "../headers/headersList";
import HotelCard from "../components/cards/hotelCards";

const demoDestinations = [
  { name: "Azure Oasis", location: "London, United Kingdom", image: "https://cf.bstatic.com/xdata/images/hotel/max1024x768/559600994.jpg?k=82cec7038d832592312183ac5a8e1726aafc1497861c7a535a8fcd460ce087a1&o=&hp=1", price: "$299" },
  { name: "The Grand Vista Hotel", location: "Moscow, Russia", image: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRCb2w1KJ9C6Vpo2cVEIUEI1ZJ117fP0g0JlQ&s", price: "$399" },
  { name: "Lumiere Suite", location: "Ontario, Canada", image: "https://otelfurnish.com/dacegug/2023/12/hotelbeds.jpg", price: "$349" },
  { name: "Azure Oasis", location: "London, United Kingdom", image: "https://cf.bstatic.com/xdata/images/hotel/max1024x768/559600994.jpg?k=82cec7038d832592312183ac5a8e1726aafc1497861c7a535a8fcd460ce087a1&o=&hp=1", price: "$299" },
  { name: "The Grand Vista Hotel", location: "Moscow, Russia", image: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRCb2w1KJ9C6Vpo2cVEIUEI1ZJ117fP0g0JlQ&s", price: "$399" },
  { name: "Lumiere Suite", location: "Ontario, Canada", image: "https://otelfurnish.com/dacegug/2023/12/hotelbeds.jpg", price: "$349" },
];

export function HotelPage() {
    const state = useSelector((state) => state.template);
    const { user, agent } = useSelector(state => state.user.userData);
    const crmData = (agent || user)?.detail?.crmData;

    return (
        <div className="w-full h-auto">
            <HeadersList />
            <div className="bg-[#EBEFF9] flex flex-col gap-10 items-left pb-10 relative">
                <HomeCarousel data={crmData?.hero?.images} />
                <div className="absolute top-0 left-0 w-full h-full bg-black/20"></div>
                <div className="origin-top-left z-10">
                    <div className="font-bold px-6 md:px-20 h-auto text-2xl origin-left mt-20">
                        {!crmData?.hero?.text ? (
                            <span className="flex flex-col gap-2">
                                <span style={{ color: state.secondaryColor }}>{'Hello there'},</span>
                                Travel Around the World
                            </span>
                        ) : (
                            <div dangerouslySetInnerHTML={{ __html: crmData?.hero?.text }} />
                        )}
                    </div>
                </div>
                <div className="flex justify-center origin-top-left px-6 md:px-20 z-10">
                    <div className="!w-full origin-top-left">
                        <SearchBarList />
                    </div>
                </div>
            </div>

            <div className="my-8 mx-4 md:mx-16">
                <h2 className="text-2xl font-bold mb-4">Top trending hotels worldwide!</h2>
                <div className="grid grid-cols-2 md:grid-cols-4 gap-6">
                    {demoDestinations.map((hotel, index) => (
                        <HotelCard key={index} hotel={hotel} />
                    ))}
                </div>
            </div>

            <FooterList />
        </div>
    );
}
