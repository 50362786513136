import { useEffect, useRef } from "react";
import FooterList from "../../footers/footerList";
import AboutHeader from "../AboutHeader";
import BlogLink from "../BlogLink";
import { useSelector } from "react-redux";

export default function About5({ preview, noScroll }) {
  const { user, agent } = useSelector((state) => state.user.userData);
  const data = (agent || user)?.detail?.crmData?.aboutUs;
  const ref = useRef();

  useEffect(() => {
    if (!noScroll)
      ref.current?.scrollIntoView({ behavior: 'smooth' });
  }, []);

  return (
    <div ref={ref}>
      <AboutHeader />

      <div className="max-w-6xl mx-auto px-4 py-16">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8 mb-16">
          <div>
            <h2 className="text-2xl font-bold mb-4">Who we are</h2>
            <p className="text-gray-600 mb-4">Lorem ipsum dolor sit amet consectetur. Odio tristique in ut viverra in vitae nisi gravida tempor. In nulla enim ullam tellus malesuada nec lacus. Eget urna ornare enim ante varius massa purus egestas.</p>
            <div className="bg-gray-200 h-40 w-full"></div>
          </div>
          <div>
            <h2 className="text-2xl font-bold mb-4">What we believe</h2>
            <p className="text-gray-600 mb-4">Lorem ipsum dolor sit amet consectetur. Odio tristique in ut viverra in vitae nisi gravida tempor. In nulla enim ullam tellus malesuada nec lacus. Eget urna ornare enim ante varius massa purus egestas.</p>
            <div className="bg-gray-200 h-40 w-full"></div>
          </div>
        </div>

        <div className="mb-16">
          <h2 className="text-2xl font-bold mb-8">Core Values</h2>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8 mb-8">
            {data?.values?.slice(0, 3).map((value, index) => (
              <div key={index}>
                <h3 className="font-bold mb-2">{value.title || `Title ${index + 1}`}</h3>
                <p className="text-gray-600">{value.description || 'Lorem ipsum dolor sit amet consectetur. Odio tristique in ut viverra in vitae nisi gravida tempor. In nulla enim ullam tellus malesuada nec lacus.'}</p>
              </div>
            ))}
          </div>
          <div className="bg-gray-200 h-60 w-full"></div>
        </div>
      </div>

      <BlogLink data={data?.blog} />
      <FooterList />
    </div>
  );
}