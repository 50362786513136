import LocationOnIcon from '@mui/icons-material/LocationOn';

function HotelCard({ hotel }) {
    return (
        <div className="flex flex-col">
            <div className="bg-white rounded-lg overflow-hidden shadow-sm mb-2">
                <img src={hotel.image} alt={hotel.name} className="w-full h-40 object-cover" />
            </div>
            <h3 className="font-semibold text-sm">{hotel.name}</h3>
            <p className="text-xs text-blue-600 flex items-center mt-1">
                <LocationOnIcon className="text-blue-600 mr-1" style={{ fontSize: '0.75rem' }} />
                {hotel.location}
            </p>
        </div>
    );
}

export default HotelCard;
