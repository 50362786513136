import React, { useEffect, useState } from 'react'
import autoRebook from '../../../controllers/booking/autoRebook';
import { useNavigate } from 'react-router-dom';
import { formatMoney, getNumber } from '../../../features/utils/formatMoney';
import Button1 from '../../form/Button1';
import { CircularProgress } from '@mui/material';
import axios from 'axios';

var cancelTokenSource = null;

export default function Rebook({data}) {
    const [loading,setLoading] = useState({rebook: true});
    const [rebooking,setRebooking] = useState(false);
    const [priceChanged,setPriceChanged] = useState(false);

    const navigate = useNavigate();
    
    console.log(data)
    useEffect(() => {
        if(data)
            handleAutoRebook({});
        //eslint-disable-next-line
    },[data])
    
    async function handleAutoRebook({acceptPriceChange=false}) {
        const payload = {
          flightBookingId: data?._id,
          acceptPriceChange
        }
        setRebooking(true)
        
        setLoading({...loading,rebook: true})
        // test cases
        // const res = await new Promise((resolve) => setTimeout(() => resolve({return: 0,msg: 'Failed',data: []}),2000))
        if(cancelTokenSource) {
            cancelTokenSource.cancel('Request canceled.')
        }
        cancelTokenSource = axios.CancelToken.source();
      
        const res = await autoRebook(payload,cancelTokenSource?.token);
        setLoading({...loading,rebook: false})
        if(res.return) {
          setRebooking('success')
          if(res?.data?._id)
            navigate('/order/flight/ticket/detail/'+res?.data?._id)
        } 
        else if(res?.msg === 'Price Changed') {
          // let data = (res.data?.map(obj => convertFlightObject(obj)) || [])
          setRebooking('price_change')
          let resData = [{totalAmount: 1000000}]
          setPriceChanged({
            price: resData?.at(0)?.totalAmount,
            change: resData?.at(0)?.totalAmount - (getNumber(data?.payable))
          })
        } else setRebooking('failed')
      }
  return (
    <div className='p-10 min-w-[300px]'>
      {
        rebooking === 'price_change' ? 
        <div>
          <div className="self-center max-w-[500px] ">
            <div className='card p-0 m-2 rounded-md flex flex-col'>
              {/* <h4 className='py-2'>You have been gone for too long.</h4> */}
              <h5 className='py-2 self-center'>Price Change</h5>
              {/* <p> The flight offer may have changed. Click the button to get the latest price.</p> */}
              <div className="text-primary/60">There has been a price change by :  
                <b className={`inline-block p-1 ${priceChanged?.change < 0 ? 'text-green-500':'text-red-400'}`}>{priceChanged?.change < 0 ? '-':'+'} {formatMoney(Math.abs(priceChanged?.change))}</b>
              </div>
              <p>
                The updated price is <b className="font-bold text-primary">{formatMoney(priceChanged?.price)}.</b>
              </p>
              <p>
                Do you want to continue or pick a different offer
              </p>
              <br />
              <div className='flex  flex-wrap justify-between gap-4'>
                <Button1 className='!w-auto self-end' variant='outlined' onClick={() => navigate('/order/neworder')}><span className='hidden sm:inline-block pr-1'>New</span> Offer</Button1>
                {/* <Button1 className='btn1 !w-auto self-end' onClick={() => (getPrice && getPrice()) || window.location.reload()}>Refresh</Button1> */}
                <Button1 loading={loading?.rebook === true} className='btn1 !w-auto self-end' onClick={() => handleAutoRebook({acceptPriceChange: true})}>Continue</Button1>
              </div>
            </div>
          </div>
        </div>
        : loading?.rebook ? 
        <div className='flex flex-col gap-4 justify-center items-center p-4'>
            <CircularProgress />
            <p>Please hold on while we're rebooking your order.</p>
        </div>
        : rebooking === 'success' ?
        <div>
            <h5>Your order has been booked.</h5>
        </div>
        : <div className='text-center'>
            <h5>The order is not available.</h5>
            <p>Please make another search for it</p>
        </div>
      }
    </div>
  )
}
