import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setActiveProducts } from '../../../../redux/reducers/template/templateSlice';


const ChooseProducts = () => {
  const activeProducts = useSelector((state) => state.template?.activeProducts || []);
  const dispatch = useDispatch();

  const products = [
    { id: 'flights', name: 'Flights', description: 'Sell flights available from 900+ airlines.' },
    { id: 'hotels', name: 'Accomodation', description: 'Sell accommodations from 1m+ providers in the worldwide.' },
    { id: 'tours', name: 'Tours', description: 'Sell over 800K travel experiences.' },
    { id: 'insurance', name: 'Insurance', description: 'Offer travel protection to your customers.' },
    { id: 'all', name: 'All', description: 'Offer flights, accomodation, tours and insurance to your customers.' }
  ];

  const handleProductToggle = (productId) => {
    let newActiveProducts;
    if (productId === 'all') {
      newActiveProducts = activeProducts.includes('all') ? [] : ['all'];
    } else {
      if (activeProducts.includes('all')) {
        newActiveProducts = [productId];
      } else {
        newActiveProducts = activeProducts.includes(productId)
          ? activeProducts.filter(id => id !== productId)
          : [...activeProducts, productId];
      }
      if (newActiveProducts.length === products.length - 1) {
        newActiveProducts = ['all'];
      }
    }
    dispatch(setActiveProducts(newActiveProducts));
  };

  const isProductActive = (productId) => activeProducts.includes(productId);

  return (
    <div className="mx-auto p-6 w-auto rounded-lg">
      <h2 className="mb-4 font-BrFirmaRegular text-2xl">Choose products</h2>
      <p className="text-sm text-gray-500 mb-6">Select the products to sell on your website.</p>
      
      <div className="space-y-3">
        {products.map((product) => (
          <div 
            key={product.id}
            className={`p-4 rounded-lg cursor-pointer transition-colors ${
              isProductActive(product.id)
                ? 'bg-blue-100 border border-blue-500' 
                : 'bg-gray-100 hover:bg-gray-200'
            }`}
            onClick={() => handleProductToggle(product.id)}
          >
            <div className="flex items-center">
              <div className={`w-5 h-5 mr-2 border rounded flex items-center justify-center ${
                isProductActive(product.id) ? 'bg-blue-500 border-blue-500' : 'border-gray-400'
              }`}>
                {isProductActive(product.id) && (
                  <svg className="w-3 h-3 text-white" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path>
                  </svg>
                )}
              </div>
              <span className={`font-medium ${isProductActive(product.id) ? 'text-blue-700' : 'font-BrFirmaRegular font-bold'}`}>
                {product.name}
              </span>
            </div>
            <p className={`mt-1 text-sm ${isProductActive(product.id) ? 'text-blue-600' : 'text-gray-500'}`}>
              {product.description}
            </p>
          </div>
        ))}
      </div>
{/*       
      <button className="mt-6 w-full bg-blue-600 text-white py-2 px-4 rounded-md hover:bg-blue-700 transition-colors">
        Continue
      </button> */}
    </div>
  );
};

export default ChooseProducts;