import React from "react";

function FeatureSection({ features }) {
  return (
    <div className=" py-10  sm:flex  ">
      {features.map((feature, index) => (
        <div
          key={index}
          className="flex  gap-4 px-6 md:px-20 py-4"
        >
          {/* Image section */}
          <div className="w-20 h-20 ">
            <img
              src={feature.image}
              alt={feature.title}
              className="w-full h-full object-cover rounded-md"
            />
          </div>
          {/* Text section */}
          <div className="flex flex-col justify-center">
            <h2 className="text-lg font-semibold">{feature.title}</h2>
            <p className="text-gray-600 text-sm">{feature.description}</p>
          </div>
        </div>
      ))}
    </div>
  );
}

export default FeatureSection;
