export const crmData = {
    hero: {
      text: null,
      images: [],
    },
    destinations: [],
    destination: {
      rating: null,
      title: null,
      locationName: {
        from: null,
        to: null,
      },
      originDestinations: [
        {
          from: null,
          to: null,
          departure: {
            date: null,
          },
        },
      ],
      image: null,
    },
    banner: [],
    CTA: {
      title: null,
      description: null,
      buttonText: null,
      link: null,
    },
    aboutUs: {
      backgroundImage: null,
      sections: [{
        image: null,
        title: null,
        description: null,
      }],
      values: [{
        title: null,
        description: null,
      }],
      blog: {
        text: null,
        link: null,
      },
    },
    contact: {
      backgroundImage: null,
      categories: [],
      workingDays: [{
        day: null,
        timeRange: [],
      }],
      holidays: [{
        date: null,
        timeRange: [],
      }],
      socials: [
        {name: '',link: ''}
      ],
      other: ''
    },
    terms: {
      title: '',
      heroImage: null,
      sections: [],
      faq: [
        {question: null,
          answer: null}
      ]
    },
    deals: {
      heroText: null,
      heroImage: null,
      flightList: [{
        dealsName: null,
        from: null,
        to: null,
        actualAmount: null,
        discountPercentage: null,
        icon: null
      }],
      hotelList: [{
        dealsName: null,
        location: null,
        actualAmount: null,
        discountPercentage: null,
        icon: null
      }],
      tourList: [{
        dealsName: null,
        destination: null,
        duration: null,
        actualAmount: null,
        discountPercentage: null,
        icon: null
      }]
    },
    testimonial:{
      heroText: null,
      heroImage: null,
      testimonials:[{
        profileImage: null,
        clientName: null,
        rating:null,
        title: null,
        review: null
      }]
    },
    privacyPolicy: {
      heroText: null,
      policies: []
    },
    externalLinks:[
      {
        title: null,
        urlLink: null,
        includesFooter: true
      }
    ]
}

//new
// destination: [{
//   title: '',
//   query: '',
//   image: null,
// }],
// quickInfo: [
//   {image: '',title: '',description: '',other: ''}
// ],
// banner: [{image: '',link: ''}],
