import { useSelector } from "react-redux"

import Hero2 from "./hero2";
import Hero1 from "./hero1";
import { Hero3 } from "./hero3";

const HeroList = ({id,overrideImage,title,description}) => {
    const state = useSelector((state)=> state.template);  
    console.log(`Selected Hero Set: ${state.template}`);
    switch (id || state.heroStyle){
        case "1":
            return  <Hero1 state={state} overrideImage={overrideImage}  title={title} description={description}  logo={state.logoImage ? state.logoImage : undefined} primaryColor={state.primaryColor} secondaryColor={state.secondaryColor}/>
        case "2":
            return <Hero2  overrideImage={overrideImage} logo={state.logoImage ? state.logoImage : undefined} title={title} description={description} primaryColor={state.primaryColor} secondaryColor={state.secondaryColor}/>
        case "3":
            return <Hero3  overrideImage={overrideImage} logo={state.logoImage ? state.logoImage : undefined} primaryColor={state.primaryColor} secondaryColor={state.secondaryColor}/>
        default:
            return <Hero1  state={state} overrideImage={overrideImage} logo={state.logoImage ? state.logoImage : undefined} primaryColor={state.primaryColor} secondaryColor={state.secondaryColor}/>
    }
}

export default HeroList