// DestinationCard.js
import React from 'react';
import LocationOnIcon from '@mui/icons-material/LocationOn'; // Material UI icon

const DestinationCard = ({ destination }) => {
  return (
    <div className="relative  h-[300px] flex-shrink-0 bg-white rounded-lg overflow-hidden shadow-lg">
      <img
        src={destination.image}
        alt={destination.name}
        className="w-full h-full object-cover"
      />
      <div className="absolute bottom-0 left-0 right-0 bg-gradient-to-t from-black/70 to-transparent p-4">
        <h3 className="text-white font-semibold text-sm">{destination.name}</h3>
        <div className="flex items-center text-white text-sm">
          <LocationOnIcon className="mr-1" style={{ fontSize: 18 }} />
          {destination.location}
        </div>
      </div>
    </div>
  );
};

export default DestinationCard;
