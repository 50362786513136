import React from "react";

function Hero4({ title, description, buttonText, buttonLink, coverImage }) {
  return (
    <div
      className="relative w-full h-80 md:h-96 rounded-xl overflow-hidden"
      style={{
        backgroundImage: `url(${coverImage})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
      }}
    >
      <div className="absolute inset-0 bg-black/40 flex flex-col justify-center items-start px-6 md:px-20">
        <h2 className="text-white text-2xl md:text-4xl font-bold mb-4">
          {title}
        </h2>
        <p className="!text-white text-lg mb-6">{description}</p>
        <a
          href={buttonLink}
          className="bg-white text-black px-4 py-2 rounded-md font-semibold"
        >
          {buttonText}
        </a>
      </div>
    </div>
  );
}

export default Hero4;
