import React, { useEffect, useRef, useState } from "react";
import Radio from "@mui/material/Radio";
import HeadersList from "../../../../components/templates/headers/headersList";
import { useDispatch, useSelector } from "react-redux";
import { setHeaderStyle } from "../../../../redux/reducers/template/templateSlice";


const HeaderStyle = () => {
  const state = useSelector((state) => state.template);
  const [scale, setScale] = useState(0.4);
  const dispatch = useDispatch();
  const iframeRefScale = useRef(null);
  const updateHeaderStyle = (value) => {
    dispatch(setHeaderStyle(value))
  };
  let curHeight = window.innerHeight;
    const updateScale = () => {
        let screenWidth = window.innerWidth;
        if (iframeRefScale.current) {
          setScale((iframeRefScale.current.offsetWidth / screenWidth));
        }
    }
    useEffect(()=>{
      window.addEventListener('resize', updateScale)
      updateScale()
      return ()=>{
        window.removeEventListener('resize', updateScale)
      }
    },[])
  return (
    <div className="p-6 mx-auto">
      <div>
        <h1 className="font-BrFirmaRegular text-2xl my-0">
          {" "}
          Select a header style
        </h1>
        <p className="font-BrFirmaRegular my-2">
          Choose a header for your website.
        </p>
      </div>
      <div className="mt-10 overflow-hidden">
        <span className=" font-bold font-BrFirmaRegular">
          {" "}
          Select header style
        </span>
        <div className="grid grid-flow-row gap-4 mt-4" ref={iframeRefScale}>
        {Array.from({ length: 2 }, (_, index) => {
          const id = index + 1;
          return (
            <div
              key={id} 
              className={`cursor-pointer ${
                state.headerStyle === String(id)
                  ? "bg-[#C4E9E3]"
                  : "bg-[#F8F8F8]"
              } p-2 rounded-md shadow-sm`}
              onClick={() => updateHeaderStyle(String(id))}
            >
              <Radio
                size="small"
                color="success"
                checked={state.headerStyle === String(id)}
              />
              <span className="mt-2 font-poppins text-sm font-bold text-[#2E2E32]">
                {`Header ${id}`}
              </span>
              <div className="mt-2 origin-top-left w-screen" style={{transform: `scale(${scale})`}}>
                <div className="pr-10">
                  <HeadersList id={String(id)}/>
                </div>
              </div>
            </div>
          );
        })}

        </div>
      </div>
    </div>
  );
};

export default HeaderStyle;
