import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { clone } from "../../../features/utils/objClone";


export const initTemplateState = {
    layoutType: 1,
    logoImage: null,
    primaryColor: "#000000",
    secondaryColor: "#1E61DC",
    fontFamily: "Arial, sans-serif",
    headerStyle: "1",
    listingStyle: "1",
    searchbarStyle: "2",
    footerStyle: "1",
    selectedPages: ['about','contact','t&c','destination','policy','faq'],//
    offerFilterStyle: "1",
    flightOfferSort: "1",
    aboutLayout: 1,
    contactLayout: 1,
    activeProducts: ['all'],
    paymentMethods:['all'],
    heroStyle: "1"
};

let initialState = clone(initTemplateState)


let UDStorage = localStorage.getItem('userData')
if(UDStorage) {
  UDStorage = JSON.parse(UDStorage);
  let savedState = UDStorage?.user?.detail?.templateData;
  if(savedState) {
    initialState = JSON.parse(savedState)
  }
}

const templateSlice = createSlice({
  name: "template",
  initialState,
  reducers: {
    setLogoImage(state, action) {
      state.logoImage = action.payload;
    },
    setPrimaryColor(state, action) {
      state.primaryColor = action.payload;
    },
    setSecondaryColor(state, action) {
      state.secondaryColor = action.payload;
    },
    setFontFamily(state, action) {
      state.fontFamily = action.payload;
    },
    setHeaderStyle(state, action) {
      state.headerStyle = action.payload;
    },
    setListingStyle(state, action) {
      state.listingStyle = action.payload;
    },
    setSearchbarStyle(state, action) {
      state.searchbarStyle = action.payload;
    },
    setFooterStyle(state, action) {
      state.footerStyle = action.payload;
    },
    setOfferFilterStyle(state, action) {
      state.offerFilterStyle = action.payload;
    },
    setFlightOfferSort(state, action) {
      state.flightOfferSort = action.payload;
    },
    setSelectedPages(state, action) {
      const id = action.payload;
      if (state.selectedPages.includes(id)) {
        state.selectedPages = state.selectedPages.filter((pageId) => pageId !== id);
      } else {
        state.selectedPages.push(id);
      }
    },
    setResetState(state) {
      state = initialState;
    },
    setTemplate(state, action) {
      return {
        ...state,
        ...action.payload
      }
    },
    setActiveProducts(state,action){
      state.activeProducts = action.payload;
    },
    setPaymentMethods(state,action){
      state.paymentMethods = action.payload;
    },
    setHeroStyle(state, action) {
      state.heroStyle = action.payload;
    },
  },
});

export const { setTemplate, setLogoImage, setPrimaryColor, setSecondaryColor, setFontFamily, setHeaderStyle, setListingStyle, setSearchbarStyle, setFooterStyle, setSelectedPages, setResetState, setOfferFilterStyle, flightOfferSort, setActiveProducts,setPaymentMethods,setHeroStyle } = templateSlice.actions;

export default templateSlice.reducer;
