import React from 'react';
import FooterList from '../footers/footerList';

const ExternalLinks = ({ crmData }) => {
  return (
    <div className="flex flex-col min-h-screen">
      <main className="flex-grow">
        <div className="container mx-auto px-4 py-8">
          <h1 className="text-3xl font-bold mb-6">Navigation Links</h1>
          
          {/* External Links content */}
          <div className="space-y-4">
            <p>Here you can find useful external links:</p>
          </div>
        </div>
      </main>

      <footer>
        <FooterList />
      </footer>
    </div>
  );
};

export default ExternalLinks;