import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import FooterList from "../footers/footerList";
import FAQList from "../components/sections/faqList";
import HeadersList from "../headers/headersList";
import HeroList from "../heroStyles/heroList";

export default function FAQPage({ preview, noScroll }) {
  const { user, agent } = useSelector((state) => state.user.userData);
  const data = (agent || user)?.detail?.crmData?.aboutUs;
  const ref = useRef();

  useEffect(() => {
    if (!noScroll) ref.current?.scrollIntoView({ behavior: "smooth" });
  }, []);

  // Move FAQ demo data here
  const faqData = [
    {
      question: "What is your return policy?",
      answer: "We offer a 30-day return policy for unused items.",
    },
    {
      question: "How do I track my order?",
      answer:
        "You can track your order using the tracking link sent to your email.",
    },
    {
      question: "Do you ship internationally?",
      answer:
        "Yes, we ship to most countries. Shipping fees vary based on location.",
    },
    {
      question: "What payment methods do you accept?",
      answer:
        "We accept Visa, Mastercard, PayPal, and other major payment methods.",
    },
    {
      question: "How can I contact customer support?",
      answer: "You can contact our support team through the Contact Us page.",
    },
  ];

  return (
    <div ref={ref}>
      <HeadersList />

      <HeroList
        title="FAQ"
        overrideImage="https://adnord.de/app/uploads/2023/03/faq-header-grafik-neu-e1680089065751.jpg"
      />

      <div className="max-w-2xl mx-auto px-4 py-12 text-center">
        <h1 className="text-4xl font-bold text-gray-900 mb-4 leading-tight">
          Frequently Asked Questions
        </h1>
        <p className="text-lg text-gray-600 mb-8">
          Questions to help you best know and understand us.
          <br />
          Can't find what you're looking for?{" "}
          <a href="#" className="text-blue-600 hover:underline">
            Contact Us
          </a>
        </p>
      </div>

      {/* Pass faqData as props to FAQList */}
      <FAQList faqs={faqData} />

      <FooterList />
    </div>
  );
}
