import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import FooterList from "../../footers/footerList";
import { useEffect, useRef, useState } from "react";
import HeroList from "../../heroStyles/heroList";

// Importing Material Icons
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import InstagramIcon from "@mui/icons-material/Instagram";
import TwitterIcon from "@mui/icons-material/Twitter";
import PhoneIcon from "@mui/icons-material/Phone";
import LocationOnIcon from "@mui/icons-material/LocationOn";

export default function Contact4({ noScroll }) {
  const location = useLocation();
  const id = location.hash.slice(1);
  const [nav, setNav] = useState(id || "query");

  const { user, agent } = useSelector((state) => state.user.userData);
  const crmData = (agent || user)?.detail?.crmData;

  const ref = useRef();

  useEffect(() => {
    if (!noScroll) ref.current?.scrollIntoView({ behavior: "smooth" });
  }, []);

  useEffect(() => {
    setNav(id || "query");
  }, [id]);

  // Destructure the crmData.contact object
  const {
    backgroundImage,
    socials = [],
    workingDays = [],
    holidays = [],
    other,
  } = crmData?.contact || {};

  return (
    <div ref={ref}>
      {/* Hero Section */}
      <HeroList overrideImage={backgroundImage} title="Contact Us" />

      <div className="flex justify-center py-10 flex-col items-center">
        {/* Main Contact Section */}
        <div className="max-w-2xl mx-auto px-4 py-12 text-center">
          <h1 className="text-4xl font-bold text-gray-900 mb-4 leading-tight">
            How can we help you?
          </h1>
          <p className="text-lg text-gray-600 mb-8">
            Get in touch with us for any inquiries, feedback, or support. We're
            always happy to hear from you and help in any way we can.
          </p>
        </div>

        <div className="w-full max-w-6xl px-6">
          <div className="grid md:grid-cols-3 gap-6 text-center bg-gray-100 p-8 rounded-2xl">
            {/* Chat with Us Section */}
            <div>
              <h3 className="text-xl font-bold">Chat with us</h3>
              <p className="text-gray-600">
                Speak to our friendly team via social media.
              </p>
              <div className="flex flex-col items-center gap-2 mt-4">
                {socials.map((social) => (
                  <a
                    key={social.name}
                    href={social.link}
                    className="text-gray-600 flex items-center gap-2"
                  >
                    {social.name === "WhatsApp" && (
                      <WhatsAppIcon className="text-blue-600" />
                    )}
                    {social.name === "Instagram" && (
                      <InstagramIcon className="text-blue-600" />
                    )}
                    {social.name === "Twitter" && (
                      <TwitterIcon className="text-blue-600" />
                    )}
                    {social.name}
                  </a>
                ))}
              </div>
            </div>

            {/* Call Us Section */}
            <div>
              <h3 className="text-xl font-bold">Call Us</h3>
              <p className="text-gray-600">
                Give us a call at anytime 24/7 Monday to Saturday.
              </p>
              <div className="flex flex-col items-center gap-2 mt-4">
                <a
                  href="tel:08139304892"
                  className="text-gray-600 flex items-center gap-2"
                >
                  <PhoneIcon className="text-blue-600" /> 08139304892
                </a>
                <a
                  href="tel:08139304892"
                  className="text-gray-600 flex items-center gap-2"
                >
                  <PhoneIcon className="text-blue-600" /> 08139304892
                </a>
              </div>
            </div>

            {/* Pay Us a Visit Section */}
            <div>
              <h3 className="text-xl font-bold">Pay us a visit</h3>
              <p className="text-gray-600">
                Come have a chat with us in person Monday to Friday 8am to 5pm.
              </p>
              <div className="flex flex-col items-center gap-2 mt-4">
                <a href="#" className="text-gray-600 flex items-center gap-2">
                  <LocationOnIcon className="text-blue-600" /> Company address
                  here
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <FooterList />
    </div>
  );
}
