import { Add, DashboardOutlined } from "@mui/icons-material";
import React, { useEffect, useRef, useState } from "react";
import { FileUploader } from "react-drag-drop-files";
import { useDispatch, useSelector } from "react-redux";
import { crmData } from "../../../../data/customer/CRM";
import Button1 from "../../../form/Button1";
import updateProfile from "../../../../controllers/user/updateProfile";
import { enqueueSnackbar } from "notistack";
import Icon from "../../../HOC/Icon";
import { clone } from "../../../../features/utils/objClone";
import SectionForm from "./SectionForm";
import ValuesForm from "./ValuesForm";
import BlogForm from "./BlogForm";
import SectionList from "./SectionList";
import { setUser } from "../../../../redux/reducers/userSlice";
import ValuesList from "./ValuesList";
import AboutLayoutOption from "../../about/aboutLayoutOption";


export default function AboutCRM() {
  const state = useSelector((state) => state.template);
  const { user } = useSelector((state) => state.user.userData);
  let dbData = clone(user?.detail?.crmData?.aboutUs || crmData.aboutUs);
  dbData.savedBG = dbData?.backgroundImage;
  dbData.backgroundImage = dbData?.backgroundImage;
  dbData.sections = dbData?.sections?.map((obj) => ({
    ...obj,
    savedImage: obj?.image,
  }));
  const defData = clone(dbData);
  const [data, setData] = useState(defData);
  const [bg, setBG] = useState();
  const [loading, setLoading] = useState(false);
  const [showLayoutChooser, setShowLayoutChooser] = useState(false); // Toggle for layout chooser

  const dispatch = useDispatch();

  async function handleSubmit(ev) {
    ev?.preventDefault();
    setLoading(true);
    const res = await updateProfile({
      crmData: { ...(user?.detail?.crmData || {}), aboutUs: data },
    });
    setLoading(false);
    if (res.return) {
      enqueueSnackbar("Page Updated", { variant: "success" });
      console.log(res.data);
      dispatch(setUser(res.data));
    } else enqueueSnackbar(res.msg, { variant: "error" });
  }

  async function handleBG(file) {
    setBG({ file, url: window.URL.createObjectURL(file) });
    const fileReader = new FileReader();
    fileReader.readAsDataURL(file);
    fileReader.onload = () => {
      const base64Data = fileReader.result.split(",")[1];
      setData({ ...data, backgroundImage: base64Data });
    };
  }

  function addSection(obj) {
    const temp = { ...data };
    temp.sections.push(obj);
    setData(temp);
  }

  function addValue(obj) {
    const temp = { ...data };
    temp.values.push(obj);
    setData(temp);
  }

  function handleSectionRemove(i) {
    const temp = { ...data };
    temp.sections = temp.sections.filter((_, ind) => ind !== i);
    setData(temp);
  }

  function handleSectionChange(obj, i) {
    if (i === null || i === undefined) return addSection(obj);
    const temp = clone(data);
    temp.sections[i] = obj;
    setData(temp);
  }

  function handleValueRemove(i) {
    const temp = { ...data };
    temp.values = temp.values.filter((_, ind) => ind !== i);
    setData(temp);
  }

  function handleValueChange(obj, i) {
    if (i === null || i === undefined) return addValue(obj);
    const temp = clone(data);
    temp.values[i] = obj;
    setData(temp);
  }

  let currentHost = window.location.protocol + "//" + window.location.host;
  const iframeRefScale = useRef();
  const [scale, setScale] = useState(0.3);

  const updateScale = () => {
    let screenWidth = window.innerWidth;
    if (iframeRefScale.current) {
      setScale(iframeRefScale.current.offsetWidth / screenWidth);
    }
  };

  useEffect(() => {
    window.addEventListener("resize", updateScale);
    updateScale();
    return () => {
      window.removeEventListener("resize", updateScale);
    };
  }, []);

  return (
    <div className="pd-md flex gap-4 flex-wrap md:flex-nowrap overflow-hidden">
      {showLayoutChooser ? (
        <AboutLayoutOption
          onSave={() => {
            setShowLayoutChooser(false);
            enqueueSnackbar("Layout saved", { variant: "success" });
          }}
          onBack={() => setShowLayoutChooser(false)}
        />
      ) : (
        <form onSubmit={handleSubmit} className="flex flex-col gap-4 py-10">
          <div className="justify-end flex">
            <button
              onClick={() => setShowLayoutChooser(true)} // Show layout chooser
              className="flex items-center bg-blue-100 text-blue-900 px-3 py-1 text-sm rounded-md hover:bg-blue-300 transition duration-300"
            >
              <DashboardOutlined className="mr-1" />
              Change Layout
            </button>
          </div>
          <div className="bg-primary/5">
            <FileUploader
              handleChange={handleBG}
              name={"files"}
              types={["JPG", "JPEG", "PNG", "GIF"]}
            >
              <div className="flex h-20 mt-2 pd-md">
                <div className="flex justify-center items-center bg-silverColor h-full w-20 rounded-md">
                  {bg?.url || data?.savedBG ? (
                    <img
                      src={bg?.url || data?.savedBG}
                      className="h-20 w-20 rounded-md object-scale-down"
                    />
                  ) : (
                    <Add fontSize="large" />
                  )}
                </div>
                <div className="flex flex-col justify-center ml-2">
                  <h1 className="text-xl font-BrFirmaBold font-bold my-0">
                    Background Image
                  </h1>
                </div>
              </div>
            </FileUploader>
          </div>

          <div className="flex gap-4 items-center flex-wrap py-4 pd-md">
            <h5>Section 1</h5>
            <SectionList
              data={data?.sections}
              handleChange={(obj, i) => handleSectionChange(obj, i)}
              remove={(i) => handleSectionRemove(i)}
            />
          </div>

          <div className="bg-primary/5 flex gap-4 items-center flex-wrap pd-md text-center">
            <h5>Values</h5>
            <ValuesList
              data={data?.values}
              handleChange={(obj, i) => handleValueChange(obj, i)}
              remove={(i) => handleValueRemove(i)}
            />
          </div>

          <div className="border p-2 pd-md text-center">
            <BlogForm
              obj={data?.blog}
              handleChange={(obj) => setData({ ...data, blog: obj })}
            />
          </div>

          <div className="self-end pd-md py-4">
            <Button1 loading={loading} type="submit">
              Save and preview
            </Button1>
          </div>
        </form>
      )}
      <div className="origin-top-left md:w-[30%]">
        <div className="flex justify-center mb-6">
          <button
            className="text-xs flex gap-2 bg-primary/5 p-2 px-4"
            onClick={() =>
              window.open(currentHost + "/preview/template/about", "_blank")
            }
          >
            Preview
          </button>
        </div>

        <iframe
          src={currentHost + "/preview/template/about"}
          className="w-screen origin-top-left overflow-hidden min-h-[90vh]"
          style={{ height: `${100 / scale}%`, transform: `scale(${scale})` }}
        />
      </div>
    </div>
  );
}
