import { useState, useEffect } from 'react';
import { Icon } from '@iconify/react';
import MenuIcon from '@mui/icons-material/Menu';
import Logo from '../../Logo/Logo';
import { getsubDomain } from '../../../utils/getsubDomain';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import CartLink from './CartLink';

const Header11 = ({overrideImage}) => {
  const state = useSelector(state => state.template);
  const {userData: {user, loggedIn}} = useSelector(state => state.user);
  const [menuVisible, setMenuVisible] = useState(false);
  const agency = getsubDomain();
  const location = useLocation();
  const [activeLink, setActiveLink] = useState('');

  const dispatch = useDispatch();

  useEffect(() => {
    const path = location.pathname;
    if (path.includes('/preview/template/flight')) {
      setActiveLink('flight');
    } else if (path.includes('/preview/template/hotel')) {
      setActiveLink('stays');
    } else if (path.includes('/preview/template/tours')) {
      setActiveLink('tours');
    } else if (path.includes('/preview/template/faq')) {
      setActiveLink('faq');
    } else {
      setActiveLink('');
    }
  }, [location]);

  const toggleMenu = () => {
    setMenuVisible(!menuVisible);
  };

  return (
    <div className="bg-white shadow-md flex justify-between items-center py-4 px-6">
      {/* Left Section - Logo */}
      <div className="flex items-center">
        <Logo iconOnly={true} overrideImage={overrideImage} />
      </div>

      {/* Middle Section - Navigation Links */}
      <div className="hidden md:flex flex-1 justify-center gap-8 font-bold items-center">
        <Link
          to='/preview/template/flight'
          className={`px-3 py-2 rounded-md ${activeLink === "flight" ? 'bg-blue-500 text-white' : 'text-black'}`}
        >
          Flights
        </Link>
        <div className="border-l h-6 border-gray-300"></div>
        <Link
          to='/preview/template/hotel'
          className={`px-3 py-2 rounded-md ${activeLink === "stays" ? 'bg-blue-500 text-white' : 'text-black'}`}
        >
          Accommodations
        </Link>
        <div className="border-l h-6 border-gray-300"></div>
        <Link
          to='/preview/template/tours'
          className={`px-3 py-2 rounded-md ${activeLink === "tours" ? 'bg-blue-500 text-white' : 'text-black'}`}
        >
          Things to do
        </Link>
        <div className="border-l h-6 border-gray-300"></div>
        <Link
          to='/preview/template/faq'
          className={`px-3 py-2 rounded-md ${activeLink === "faq" ? 'bg-blue-500 text-white' : 'text-black'}`}
        >
          FAQ
        </Link>
      </div>

      {/* Right Section - Cart, Help, and Account Links */}
      <div className="flex items-center gap-4">
        <button className='flex gap-2 items-center intercomButton'>
          <Icon icon="ph:question" className='text-xl' style={{color: state.secondaryColor}} />
          <span>Help</span>
        </button>

        <CartLink />

        {loggedIn ? (
          <Link to='/account' className='flex gap-2 items-center justify-center rounded-md bg-[#EFF3F5] px-2 py-2'>
            <Icon icon="heroicons:user" className='text-xl' style={{color: state.secondaryColor}} />
          </Link>
        ) : (
          <Link to='/?view=login' className='flex gap-2 items-center justify-center rounded-md bg-[#EFF3F5] px-2 py-2'>
            <span>Sign In</span>
          </Link>
        )}
      </div>

      {/* Mobile Menu Button */}
      <div className="md:hidden">
        <MenuIcon onClick={toggleMenu} className="cursor-pointer" />
      </div>

      {/* Mobile Menu */}
      <div className={`md:hidden flex-col items-center ${menuVisible ? 'flex' : 'hidden'}`}>
        <Link
          to='/preview/template/flight'
          className={`px-3 py-2 rounded-md ${activeLink === "flight" ? 'bg-blue-500 text-white' : 'text-black'}`}
        >
          Flights
        </Link>
        <div className="border-l h-6 border-gray-300"></div>
        <Link
          to='/preview/template/hotel'
          className={`px-3 py-2 rounded-md ${activeLink === "stays" ? 'bg-blue-500 text-white' : 'text-black'}`}
        >
          Accommodations
        </Link>
        <div className="border-l h-6 border-gray-300"></div>
        <Link
          to='/preview/template/tours'
          className={`px-3 py-2 rounded-md ${activeLink === "tours" ? 'bg-blue-500 text-white' : 'text-black'}`}
        >
          Things to do
        </Link>
        <div className="border-l h-6 border-gray-300"></div>
        <Link
          to='/preview/template/faq'
          className={`px-3 py-2 rounded-md ${activeLink === "faq" ? 'bg-blue-500 text-white' : 'text-black'}`}
        >
          FAQ
        </Link>
      </div>
    </div>
  );
}

export default Header11;