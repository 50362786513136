import { useSelector } from "react-redux";
import SearchBarList from "../Searchbars/searchBarList";
import FooterList from "../footers/footerList";
import HomeCarousel from "../layouts/HomeCarousel";
import FeatureSection from "../components/sections/featureSection";
import Hero4 from "../heroStyles/hero4";

// Feature data
const featureData = [
  {
    title: "Travel with confidence",
    description: "Be served by travel agents that know! 24/7 service just a phone-call away.",
    image: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTG9BXk0vX11XIFxR64R4fe0afcHbpMMZQAmg&s",
  },
  {
    title: "New deals every day",
    description: "We have negotiated with every major airline to pass the savings on to you.",
    image: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTOuCCs734vCdAgldzGEJ6SIwhNQyhqPjri4Q&s",
  },
  {
    title: "The flight experts",
    description: "We are dedicated to bringing customers great flights at great prices.",
    image: "https://purepng.com/public/uploads/large/purepng.com-airplaneairplanevehiclestransportjetplanetravelskyaircraft-981525069349twm8y.png",
  },
];

export function Layout5() {
  const state = useSelector((state) => state.template);
  const { user, agent } = useSelector((state) => state.user.userData);
  const crmData = (agent || user)?.detail?.crmData;

  return (
    <div className="w-full h-auto">
      <div className="bg-[#EBEFF9] flex flex-col gap-10 items-left pb-10 relative">
        <HomeCarousel data={crmData?.hero?.images} />
        <div className="absolute top-0 left-0 w-full h-full bg-black/20"></div>
        <div className="origin-top-left z-10">
          <div className="font-bold px-6 md:px-20 h-auto text-2xl origin-left mt-20">
            {!crmData?.hero?.text ? (
              <span className="flex flex-col gap-2">
                <span style={{ color: state.secondaryColor }}>
                  {"Hello there"},
                </span>
                Book cheap flights with your one-step travel shop!
              </span>
            ) : (
              <div dangerouslySetInnerHTML={{ __html: crmData?.hero?.text }} />
            )}
          </div>
        </div>
        <div className="flex justify-center origin-top-left px-6 md:px-20 z-10">
          <div className="!w-full origin-top-left">
            <SearchBarList id={4} />
          </div>
        </div>
      </div>

      {/* Add the FeatureSection here */}
      <div className="w-full flex justify-center px-6 md:px-20 py-10">
        <FeatureSection features={featureData} />
      </div>

      {/* Hero Section */}
      <div className="w-full px-6 md:px-20 py-10">
        <Hero4
          title="Can't decide where to go?"
          description="Take a look at the best flight deals"
          buttonText="Explore the best deals"
          buttonLink="/deals"
          coverImage="https://cdn.neamb.com/-/media/images/neamb/article-pages/travel_vacations/tourist_attractions_that_are_actually_worth_the_trip/6_tourist_attractions_that_are_actually_worth_the_trip_607971905_842x474.jpg"
        />
      </div>

      {/* Footer */}
      <FooterList />
    </div>
  );
}
