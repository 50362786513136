import { useSelector } from "react-redux";
import SearchBarList from "../Searchbars/searchBarList";
import TopDestination from "../dashboard/Home/TopDestination";
import FooterList from "../footers/footerList";
import Banner from "../layouts/Banner";
import HomeCarousel from "../layouts/HomeCarousel";
import HeadersList from "../headers/headersList";
import ThreeCardGrid from "../components/sections/threeCardGrid";
import TestimonialCard from "../components/cards/testimonialCard";
import DestinationCard from "../components/cards/destinationsCard";

// MUI Icons
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import ChatIcon from "@mui/icons-material/Chat";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import HotelCard from "../components/cards/hotelCards";
import DealsSummaryCard from "../components/cards/dealsSummaryCard";
import FaqList from "../components/sections/faqList";

const demoDestinations = [
  {
    name: "Eiffel Tower, Versailles & Seine River Combo Tour",
    location: "Paris, France",
    image:
      "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR73oz60tYFhaapkWemQ7oggHztiDsuxBoLDQ&s",
    price: "$299",
  },
  {
    name: "Moscow Kremlin & Red Square",
    location: "Moscow, Russia",
    image:
      "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSWMkrdvzZJ5zeeXljbPI5h4d21FMuwVeUsHA&s",
    price: "$399",
  },
  {
    name: "London Eye & Thames River Cruise",
    location: "London, UK",
    image:
      "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTNLhqqEOOBzvZ44xKRuDR_bVEtpsMLWGh78Q&s",
    price: "$349",
  },
  {
    name: "Eiffel Tower, Versailles & Seine River Combo Tour",
    location: "Paris, France",
    image:
      "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR73oz60tYFhaapkWemQ7oggHztiDsuxBoLDQ&s",
    price: "$299",
  },
  {
    name: "Moscow Kremlin & Red Square",
    location: "Moscow, Russia",
    image:
      "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSWMkrdvzZJ5zeeXljbPI5h4d21FMuwVeUsHA&s",
    price: "$399",
  },
  {
    name: "London Eye & Thames River Cruise",
    location: "London, UK",
    image:
      "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTNLhqqEOOBzvZ44xKRuDR_bVEtpsMLWGh78Q&s",
    price: "$349",
  },
];

const demoHotels = [
  {
    name: "Burj Khalifa",
    location: "Dubai, UAE",
    price: "$150.00",
    image:
      "https://www.cnet.com/a/img/resize/c3e80a0a967c57d0fa7d6c9b2aeced2c20c9c2e9/hub/2016/12/16/85151223-cdd5-4b48-9be5-d8e39b133ebf/burj-khalifa-1.jpg?auto=webp&width=1200",
  },
  {
    name: "The Grand Vista Hotel",
    location: "New York, USA",
    price: "$145.00",
    image:
      "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTIdGjNsFNMQ7hMg842yxrTmX0FxLmkUTonfg&s",
  },
  {
    name: "Lumiere Suites",
    location: "Paris, France",
    price: "$165.00",
    image:
      "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTgrycXW-zppwILSxXnY6WGkQwc-MIumwTDhA&s",
  },
  {
    name: "Horizon Towers Hotel",
    location: "Tokyo, Japan",
    price: "$130.00",
    image:
      "https://abu-dhabi.realestate/wp-content/uploads/2021/08/Horizon-Towers-007.jpg",
  },
];

const demoTestimonials = [
  {
    name: "Emma Akinjide",
    location: "Lagos, Nigeria",
    image:
      "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSub0wr96lOiopPtXAYOGwOMbhXZFxbrxKrUg&s",
    title: "Amazing service!",
    review: "The booking process was smooth and the customer support was exceptional. I had all my questions answered promptly, making my travel experience stress-free.",
  },
  {
    name: "Victor Chiremba",
    location: "Harare, Zimbabwe",
    image:
      "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTuvcq5fPdC59b5n1rRWJUppMh5fiOINKy00Q&s",
    title: "Great deals found!",
    review: "I found great deals on this platform that I couldn't resist. The interface was easy to navigate, and I felt confident booking my next trip here.",
  },
  {
    name: "Oluwani Ebube",
    location: "Accra, Ghana",
    image:
      "https://www.hollywoodreporter.com/wp-content/uploads/2019/07/jav519a_0214b-h_2019.jpg?w=1296&h=730&crop=1",
    title: "User-friendly experience!",
    review: "The variety of options and the user-friendly interface made planning my vacation a breeze. I could easily compare hotels and prices, ensuring I got the best deal.",
  },
];


const gridData = [
  {
    image:
      "https://cdn.prod.website-files.com/65ce038ac58a7f988fb59ccd/660dde5fd75afffac6083973_Insurance%20Basics%20Everything%20you%20need%20to%20know-min.webp",
    text1: "Protect your family when you travel",
    text2: "with Insurance",
  },
  {
    image:
      "https://images.pexels.com/photos/2033343/pexels-photo-2033343.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500",
    text1: "Destinations Available",
    text2: "1200",
  },
  {
    image:
      "https://www.privateislandsonline.com/uploads/resize/_597_image_fe02c338d8.jpg-1074-822.jpg",
    text1: "Beyond travel, creating",
    text2: "memories of a lifetime!",
  },
];

const demoDeals = [
  {
    name: "Burj Khalifa",
    location: "Dubai, UAE",
    price: "$150.00",
    image:
      "https://thumbs.dreamstime.com/b/happy-travel-woman-vacation-concept-funny-traveler-enjoy-her-trip-ready-to-adventure-happy-travel-woman-vacation-concept-118679424.jpg",
  },
  {
    name: "The Grand Vista Hotel",
    location: "New York, USA",
    price: "$145.00",
    image:
      "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS_LvvvhWgV3oEpfXRrOn_jvl3bWzjTy4h8DQ&s",
  },
];

const faqData = [
  {
    question: "What is your return policy?",
    answer: "We offer a 30-day return policy for unused items.",
  },
  {
    question: "How do I track my order?",
    answer:
      "You can track your order using the tracking link sent to your email.",
  },
  {
    question: "Do you ship internationally?",
    answer:
      "Yes, we ship to most countries. Shipping fees vary based on location.",
  },
  {
    question: "What payment methods do you accept?",
    answer:
      "We accept Visa, Mastercard, PayPal, and other major payment methods.",
  },
  {
    question: "How can I contact customer support?",
    answer: "You can contact our support team through the Contact Us page.",
  },
];

export function Layout4() {
  const state = useSelector((state) => state.template);
  const { user, agent } = useSelector((state) => state.user.userData);
  const crmData = (agent || user)?.detail?.crmData;

  return (
    <div className="w-full h-auto">
      {/* Header List */}

      <div className="bg-[#EBEFF9] flex flex-col gap-10 items-left pb-10 relative">
        <HomeCarousel data={crmData?.hero?.images} />
        <div className="absolute top-0 left-0 w-full h-full bg-black/20"></div>
        <div className="origin-top-left z-10">
          <div className="font-bold px-6 md:px-20 h-auto text-2xl origin-left mt-20">
            {!crmData?.hero?.text ? (
              <span className="flex flex-col gap-2">
                <span style={{ color: state.secondaryColor }}>
                  {"Hello there"},
                </span>
                Book cheap flights with your one-step travel shop!
              </span>
            ) : (
              <div dangerouslySetInnerHTML={{ __html: crmData?.hero?.text }} />
            )}
          </div>
        </div>
        <div className="flex justify-center origin-top-left px-6 md:px-20 z-10">
          <div className="!w-full origin-top-left">
            <SearchBarList />
          </div>
        </div>
      </div>

      <div className="flex justify-evenly items-center p-6 py-10  bg-blue-600 text-white">
        <div className="flex items-center">
          <CalendarTodayIcon className="mr-2" />
          <div className="flex flex-col">
            <span className="font-bold">Manage your trip</span>
            <span className="text-sm font-thin">
              Make updates or cancel booking
            </span>
          </div>
        </div>
        <ArrowForwardIcon />
        <div className="flex items-center">
          <ChatIcon className="mr-2" />
          <div className="flex flex-col">
            <span className="font-bold">Need help?</span>
            <span className="text-sm font-thin">
              Our travel sharks are available 24/7
            </span>
          </div>
        </div>
        <ArrowForwardIcon />
        <div className="flex items-center">
          <AttachMoneyIcon className="mr-2" />
          <div className="flex flex-col justify-center">
            <span className="font-bold">Track your refund</span>
            <span className="text-sm font-thin">
              Check the status of a refund currently in progress
            </span>
          </div>
        </div>
      </div>
      <div className="container mx-auto p-6">
        <br />
        {/* Top destinations */}
        <div className="my-8 ">
          <h2 className="text-xl font-semibold mb-4">
            Top destinations from your location
          </h2>
          <div className="relative">
            <div className="flex overflow-x-auto space-x-4 scrollbar-thin scrollbar-thumb-gray-500 scrollbar-track-gray-300">
              {(crmData?.topDestinations || demoDestinations).map(
                (dest, index) => (
                  <DestinationCard key={index} destination={dest} />
                )
              )}
            </div>
          </div>
        </div>

        {/* <br/>
      <div className="flex flex-col items-center justify-center px-6 py-10 md:px-20 gap-4">
      
      <div className="flex justify-between w-full items-center mb-6">
        <h1 className="font-bold text-2xl">Exclusive deals just for you</h1>
        <span className="flex items-center gap-1 cursor-pointer text-blue-500 hover:underline">
          See All <ArrowForwardIcon fontSize="small" />
        </span>
      </div>

      
      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        {demoDeals.map((deal, index) => (
          <DealsSummaryCard key={index} deal={deal} />
        ))}
      </div>
    </div> */}

        <br />
        {/* Top trending hotels */}
        <div className="my-8 ">
          <h2 className="text-xl font-semibold mb-4">
            Top trending hotels worldwide!
          </h2>
          <div className="grid grid-cols-2 md:grid-cols-4 gap-6">
            {demoDestinations.map((hotel, index) => (
              <HotelCard key={index} hotel={hotel} />
            ))}
          </div>
        </div>

        <br />
        {/* Testimonials */}
        <div className="my-8 mt-10">
          <h2 className="text-xl font-semibold mb-4">What people are saying</h2>
          <div className="flex flex-wrap gap-4">
            {demoTestimonials.map((testimonial, index) => (
              <TestimonialCard
                key={index}
                image={testimonial.image}
                name={testimonial.name}
                location={testimonial.location}
                title={testimonial.title}
                review= {testimonial.review}
              />
            ))}
          </div>
        </div>
      </div>
      <div className="container mx-auto px-4 my-8">
        <h2 className="text-xl font-semibold mb-4">Travel Highlights</h2>
        <ThreeCardGrid gridData={gridData} />
      </div>

      <div className="flex flex-col md:flex-row justify-center items-center min-h-screen ">
        <div className="max-w-2xl md:w-1/2 text-center md:pr-8">
          <h1 className="text-4xl font-bold text-gray-900 mb-4 leading-tight">
            Frequently Asked Questions
          </h1>
          <p className="text-lg text-gray-600 mb-8">
            Questions to help you best know and understand us.
            <br />
            Can't find what you're looking for?{" "}
            <a href="#" className="text-blue-600 hover:underline">
              Contact Us
            </a>
          </p>
        </div>
        <div className="md:w-1/2">
          <FaqList faqs={faqData} />
        </div>
      </div>

      <FooterList />
    </div>
  );
}
