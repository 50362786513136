import React, { createContext, useEffect, useRef, useState } from 'react';
import Icon from '../../../HOC/Icon';
import Modal1 from '../../../DIsplay/Modal/Modal1';
import Button1 from '../../../form/Button1';
import TextInput from '../../../form/TextInput';
import Checkbox from '../../../form/Checkbox';
import { crmData } from '../../../../data/customer/CRM';

const ModalContext = createContext(null);

export default function ExternalLinksCRM() {
    const [data, setData] = useState({ navbarLinks: crmData.externalLinks });
    const [modalOpen, setModalOpen] = useState({ navbarLink: false });
    const [loading, setLoading] = useState({ submit: false });

    function handleUpload(ev, newData) {
        ev?.preventDefault();
        setLoading({ ...loading, submit: true });
        // Simulating an API call
        setTimeout(() => {
            setData(newData);
            setLoading({ ...loading, submit: false });
            console.log('Navbar Updated:', newData);
        }, 1000);
    }

    const EditModal = ({ children, title, data: defData, ...props }) => {
        const [value, setValue] = useState(defData || { title: '', url: '', inFooter: false });

        function handleSubmit() {
            const updData = {
                ...data,
                navbarLinks: [...data.navbarLinks, value], // appending the new value to existing links
            };
            setData((prevData) => ({ ...prevData, navbarLinks: [...prevData.navbarLinks, value] })); // update state with previous data and new link
            handleUpload(null, updData); // calling handleUpload with new data
            props?.setOpen && props?.setOpen(false);
        }
        

        return (
            <Modal1 {...props}>
                <div className="p-5 flex flex-col gap-4">
                    <div className="flex gap-4 justify-between py-4">
                        <b>{title}</b>
                        <Icon icon="mdi:close" onClick={() => props?.setOpen()} />
                    </div>
                    <ModalContext.Provider value={{ value, setValue }}>
                        {children}
                    </ModalContext.Provider>
                    <div className="flex gap-4">
                        <Button1 variant="text" onClick={() => props?.setOpen()}>Close</Button1>
                        <Button1 onClick={() => handleSubmit()}>Save</Button1>
                    </div>
                </div>
            </Modal1>
        );
    };

    function handleRemoveNavbarLink(i) {
        const temp = { ...data };
        temp.navbarLinks = temp.navbarLinks.filter((_, ind) => ind !== i);
        setData(temp);
        handleUpload(null, temp);
    }

    const sections = [
        {
            title: 'Navbar Links',
            section: [
                {
                    label: 'Links',
                    body: <NavbarLinksList handleDelete={(i) => handleRemoveNavbarLink(i)} data={data.navbarLinks} />,
                    action: (section) => (
                        <div>
                            <button
                                onClick={() => setModalOpen({ ...modalOpen, navbarLink: true })}
                                className="text-xs flex gap-2 bg-primary/5 p-2 px-4"
                            >
                                Add <Icon icon="material-symbols:add" className="!w-4 !h-4" />
                            </button>
                            <EditModal
                                open={modalOpen.navbarLink}
                                setOpen={() => setModalOpen({ ...modalOpen, navbarLink: false })}
                                title={section.title}
                            >
                                <ModalContext.Consumer>
                                    {({ value, setValue }) => (
                                        <div className="flex flex-col gap-4">
                                            <TextInput
                                                label="Title"
                                                value={value.title || ''}
                                                onChange={(ev) => setValue({ ...value, title: ev.target.value })}
                                            />
                                            <TextInput
                                                label="URL"
                                                value={value.url || ''}
                                                onChange={(ev) => setValue({ ...value, url: ev.target.value })}
                                            />
                                            <Checkbox
                                                label="Show in Footer"
                                                checked={value.inFooter || false}
                                                onChange={(ev) => setValue({ ...value, inFooter: ev.target.checked })}
                                            />
                                        </div>
                                    )}
                                </ModalContext.Consumer>
                            </EditModal>
                        </div>
                    ),
                },
            ],
        },
    ];

    let currentHost = window.location.protocol + "//" + window.location.host;
    const iframeRefScale = useRef();
    const [scale, setScale] = useState(0.3);

    const updateScale = () => {
        let screenWidth = window.innerWidth;
        if (iframeRefScale.current) {
            setScale(iframeRefScale.current.offsetWidth / screenWidth);
        }
    };

    useEffect(() => {
        window.addEventListener('resize', updateScale);
        updateScale();
        return () => {
            window.removeEventListener('resize', updateScale);
        };
    }, []);

    return (
        <div className="pd-md flex gap-4 flex-wrap md:flex-nowrap overflow-hidden">
            <div className="md:w-[65%]">
                <div className="pb-4">
                    <h3>Navigation </h3>
                    <p>Add or edit links for your navbar. You can always change them later.</p>
                </div>
                <div className="flex flex-col gap-4">
                    {sections?.map((section, i) => (
                        <div key={section?.title + i}> 
                            <div className="bg-theme1/10 p-4 py-2">{section?.title}</div>
                            {section?.section?.map((obj, j) => (
                                <div key={section?.title + obj?.label + j} className="flex gap-4 p-4 border-b">
                                    <div className="w-[150px]">{obj?.label}</div>
                                    <div className="flex-1 max-w-[64%] overflow-hidden">{obj?.body}</div>
                                    <div>{obj?.action(section)}</div>
                                </div>
                            ))}
                        </div>
                    ))}
                </div>
            </div>
            <div className="origin-top-left md:w-[30%]">
                <div className="flex justify-center mb-6">
                    <button
                        className="text-xs flex gap-2 bg-primary/5 p-2 px-4"
                        onClick={() => window.open(currentHost + '/preview/template/externalLinks', '_blank')}
                    >
                        Preview
                    </button>
                </div>
                <iframe
                    src={currentHost + '/preview/template/externalLinks'}
                    className="w-screen origin-top-left overflow-hidden min-h-[70vh]"
                    style={{ height: `${100 / scale}%`, transform: `scale(${scale})` }}
                />
            </div>
        </div>
    );
}

function NavbarLinksList({ handleDelete, data }) {
    return (
        <ul className="space-y-2">
            {data.map((link, index) => (
                <li key={index} className="flex items-center justify-between p-2 bg-gray-100 rounded">
                    <div className="flex items-center space-x-2">
                        <span className="font-semibold text-gray-700">{link.title}</span>
                        <span className="text-sm text-gray-500">({link.url})</span>
                        <span className="text-sm text-gray-400">{link.inFooter ? '(In Footer)' : ''}</span>
                    </div>
                    <button
                        onClick={() => handleDelete(index)}
                        className="text-red-500 hover:text-red-700 transition-colors duration-200"
                    >
                        <Icon icon="mdi:delete" className="w-5 h-5" />
                    </button>
                </li>
            ))}
        </ul>
    );
}
