export const flightProviders = [
  "Intra1T",
  "Intra1A",
  "Intra3A_DXB",
]

export const flightSuppliers = {
  TravX: "TravX",
  Local: "Local",
  Amadeus: "Amadeus",
  Sabre: "Sabre",
  Travelport: "Travelport",
  MyAmadeus: "MyAmadeus",
  MySabre: "MySabre",
  MyTravelport: "MyTravelport",
}