import { useSelector } from "react-redux";
import Banner from "../layouts/Banner";
import GuideLinks from "../layouts/GuideLinks";
import HomeCarousel from "../layouts/HomeCarousel";
import SearchBarList from "../Searchbars/searchBarList";
import TopDestination from "../dashboard/Home/TopDestination";
import Deals from "../layouts/Deals";

export function Hero3() {
    const state = useSelector((state)=> state.template);
    const {user,agent} = useSelector(state => state.user.userData)

    const crmData = (agent || user)?.detail?.crmData;

    return (
      <div
      className={`w-full h-auto rounded-lg`}
    >
        <div className="bg-[#EBEFF9] flex flex-col gap-10 items-left pb-10 relative">
          <HomeCarousel data={crmData?.hero?.images} />
          <div className="absolute top-0 left-0 w-full h-full bg-black/20"></div>
          <div className="origin-top-left z-10" >
            <div className="font-bold px-6 md:px-20 h-auto text-2xl origin-left mt-20">
                {!crmData?.hero?.text ? 
                  <span className="flex flex-col gap-2">
                    <span style={{color: state.secondaryColor}}>{'Hello there'},</span>
                    Book cheap flights with your one-step travel shop!
                  </span>
                :
    							<div dangerouslySetInnerHTML={{ __html: crmData?.hero?.text }} />
                }
            </div>
          </div>
          <div className="flex justify-center origin-top-left px-6 md:px-20 z-10">
            <div className="!w-full origin-top-left">
                <SearchBarList/>
            </div>
          </div>
        </div>
      

    </div>
  
    )
  }
