import { useSelector } from "react-redux";
import SearchBarList from "../Searchbars/searchBarList";
import FooterList from "../footers/footerList";
import HomeCarousel from "../layouts/HomeCarousel";
import HeadersList from "../headers/headersList";
import DestinationCard from "../components/cards/destinationsCard";


const demoDestinations = [
  { name: "Eiffel Tower, Versailles & Seine River Combo Tour", location: "Paris, France", image: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR73oz60tYFhaapkWemQ7oggHztiDsuxBoLDQ&s", price: "$299" },
  { name: "Moscow Kremlin & Red Square", location: "Moscow, Russia", image: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSWMkrdvzZJ5zeeXljbPI5h4d21FMuwVeUsHA&s", price: "$399" },
  { name: "London Eye & Thames River Cruise", location: "London, UK", image: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTNLhqqEOOBzvZ44xKRuDR_bVEtpsMLWGh78Q&s", price: "$349" },
  { name: "Eiffel Tower, Versailles & Seine River Combo Tour", location: "Paris, France", image: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR73oz60tYFhaapkWemQ7oggHztiDsuxBoLDQ&s", price: "$299" },
  { name: "Moscow Kremlin & Red Square", location: "Moscow, Russia", image: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSWMkrdvzZJ5zeeXljbPI5h4d21FMuwVeUsHA&s", price: "$399" },
  { name: "London Eye & Thames River Cruise", location: "London, UK", image: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTNLhqqEOOBzvZ44xKRuDR_bVEtpsMLWGh78Q&s", price: "$349" },
];

export function FlightPage() {
  const state = useSelector((state) => state.template);
  const { user, agent } = useSelector(state => state.user.userData);
  const crmData = (agent || user)?.detail?.crmData;

  return (
    <div className="w-full h-auto">
      <HeadersList />
      <div className="bg-[#EBEFF9] flex flex-col gap-10 items-left pb-10 relative">
        <HomeCarousel data={crmData?.hero?.images} />
        <div className="absolute top-0 left-0 w-full h-full bg-black/20"></div>
        <div className="origin-top-left z-10">
          <div className="font-bold px-6 md:px-20 h-auto text-2xl origin-left mt-20">
            {!crmData?.hero?.text ? (
              <span className="flex flex-col gap-2">
                <span style={{ color: state.secondaryColor }}>{'Hello there'},</span>
                Travel Around the World
              </span>
            ) : (
              <div dangerouslySetInnerHTML={{ __html: crmData?.hero?.text }} />
            )}
          </div>
        </div>
        <div className="flex justify-center origin-top-left px-6 md:px-20 z-10">
          <div className="!w-full origin-top-left">
            <SearchBarList id={4} />
          </div>
        </div>
      </div>

      {/* Top destinations */}
      <div className="my-8 mx-16">
        <h2 className="text-xl font-semibold mb-4">Top destinations from your location</h2>
        <div className="relative">
          <div className="flex overflow-x-auto space-x-4 scrollbar-thin scrollbar-thumb-gray-500 scrollbar-track-gray-300">
            {(crmData?.topDestinations || demoDestinations).map((dest, index) => (
              <DestinationCard key={index} destination={dest} />
            ))}
          </div>
        </div>
      </div>

      <FooterList />
    </div>
  );
}
