import React from 'react';
import { useSelector } from 'react-redux';
import FooterList from '../footers/footerList';
import HeroList from '../heroStyles/heroList';

const DealCard = ({ dealsName, from, to, actualAmount, discountPercentage, icon, departureDate, arrivalDate, airline }) => (
  <div className="bg-white rounded-lg shadow-lg flex flex-col justify-between transition-transform transform hover:scale-105 " >
    {/* Image takes the top section without padding */}
    <div className="h-48 bg-gray-100 rounded-t-lg overflow-hidden w-full">
      {icon ? (
        <img src={icon} alt={dealsName} className="w-full h-full object-cover" />
      ) : (
        <div className="text-gray-500 text-lg flex items-center justify-center h-full">No Image Available</div>
      )}
    </div>

    {/* Content section with padding */}
    <div className="p-5 flex flex-col justify-between">
      <p className="font-semibold text-lg text-gray-800 mb-1">{dealsName}</p>
      <p className="text-sm text-gray-600 mb-3">{from} to {to}</p>
      {departureDate && <p className="text-sm text-gray-600 mb-1">Departure: {departureDate}</p>}
      {arrivalDate && <p className="text-sm text-gray-600 mb-3">Arrival: {arrivalDate}</p>}
      {airline && <p className="text-sm text-gray-600 mb-3">Airline: {airline}</p>}

      {/* Price and discount at the bottom */}
      <div className="flex items-center justify-between mt-auto">
        <div className="flex items-baseline">
          <span className="text-sm text-gray-600">₦</span>
          <span className="font-bold text-lg ml-1 text-gray-800">{actualAmount}</span>
        </div>
        <div className="bg-green-500 text-white text-xs font-bold px-3 py-1 rounded-lg">
          {discountPercentage}% OFF
        </div>
      </div>
    </div>
  </div>
);


const DealsSection = ({ title, deals }) => (
  <div className="mb-12">
    <h2 className="text-2xl font-bold mb-6 text-gray-800">{title}</h2>
    <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-8">
      {deals.map((deal, index) => (
        <DealCard key={index} {...deal} />
      ))}
    </div>
  </div>
);


const Hero = ({ heroText, heroImage }) => {
  const defaultImage =
    "";

  return (
    <div className="relative max-w-7xl mx-auto rounded-lg overflow-hidden shadow-lg my-8"> {/* Adjusted width here */}
      {/* Background Image */}
      <div
        className="relative h-96 md:h-[500px] w-full bg-cover bg-center flex items-center"
        style={{
          backgroundImage: `url(${heroImage || defaultImage})`,
        }}
      >
        {/* Overlay for better text visibility */}
        <div className="absolute inset-0 bg-black opacity-50"></div>

        {/* Hero Text inside a white container on the left */}
        <div className="relative z-10 p-8 bg-white rounded-lg shadow-lg max-w-md flex flex-col justify-center lg:ml-6 sm:ml-2">
          <div className="uppercase tracking-wide text-sm text-blue-400 font-semibold">
            Travel Deals
          </div>
          <h1 className="mt-1 text-3xl md:text-4xl font-bold leading-tight text-gray-900">
            {heroText || "Exclusive Travel Deals"}
          </h1>
          <p className="mt-2 text-gray-600">
            Explore the world with amazing discounts and exclusive offers.
          </p>
          <a
            href="#"
            className="mt-4 inline-block px-6 py-2 text-sm font-medium leading-6 text-center text-white transition bg-blue-600 rounded shadow ripple hover:shadow-lg hover:bg-blue-700 focus:outline-none"
          >
            Discover Deals
          </a>
        </div>
      </div>
    </div>
  );
};



const Deals1 = () => {
  const { user, agent } = useSelector((state) => state.user.userData);
  const crmDataString = (agent || user)?.detail?.crmData;

  const demoData = {
    heroText: "Amazing Travel Deals Await!",
    heroImage: null,
    hotelList: [
      {
        dealsName: "The Grand Hotel",
        from: "2024-10-01",
        to: "2024-10-10",
        actualAmount: "120,000",
        discountPercentage: 10,
        icon: "https://images.photowall.com/products/49099/travel-concept.jpg?h=699&q=85"
      },
    ],
    tourList: [
      {
        dealsName: "Safari Adventure",
        from: "2024-09-15",
        to: "2024-09-20",
        actualAmount: "80,000",
        discountPercentage: 5,
        icon: "https://wallpapercave.com/wp/wp2481186.jpg"
      },
    ],
    flightList: [
      {
        dealsName: "Paris to Lagos",
        from: "Paris",
        to: "Lagos",
        actualAmount: "450,000",
        discountPercentage: 20,
        icon: "https://images.photowall.com/products/49099/travel-concept.jpg?h=699&q=85",
        departureDate: "2024-10-15",
        arrivalDate: "2024-10-16",
        airline: "Air France"
      },
      {
        dealsName: "New York to London",
        from: "New York",
        to: "London",
        actualAmount: "650,000",
        discountPercentage: 15,
        icon: "https://www.pixelstalk.net/wp-content/uploads/images6/Travel-Wallpaper-HD-Free-download.jpg",
        departureDate: "2024-11-01",
        arrivalDate: "2024-11-02",
        airline: "British Airways"
      },
    ]
  };

  let crmData = {};
  try {
    // Check if crmDataString is an object or a string
    if (typeof crmDataString === 'string') {
      crmData = JSON.parse(crmDataString);
    } else if (typeof crmDataString === 'object') {
      crmData = crmDataString; // Use as is if already an object
    }

    // Log the parsed CRM data for debugging
    console.log('CRM Data:', crmData);
  } catch (error) {
    console.error('Error parsing CRM data:', error);
  }

  // Merge demo data with CRM data
  const data = { ...demoData, ...crmData };
  const { flightList, hotelList, tourList, heroText, heroImage } = data;

  // Log the final data object for debugging
  console.log('Final Data:', data);

  return (
    <div className=" mx-auto">
      <HeroList  title="Deals" overrideImage={heroImage || "https://media.istockphoto.com/id/583724238/photo/summertime-fun.jpg?s=612x612&w=0&k=20&c=XOe4pBepfLYRO2wOIB3suTi9ARqXNE3XoKe70bcZAKo="} />
      <br/>
      {flightList?.length > 0 && (
        <DealsSection title="Save up to 10% on flights" deals={flightList} />
      )}
      {tourList?.length > 0 && (
        <DealsSection title="Get a discount of over 20% on tours" deals={tourList} />
      )}
      {hotelList?.length > 0 && (
        <DealsSection title="Hotel Deals" deals={hotelList} />
      )}
      <FooterList />
    </div>
  );
};

export default Deals1;

