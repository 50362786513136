import { useEffect, useRef } from "react";
import { isCustomerSite } from "../../../utils/getsubDomain";
import FooterList from "../footers/footerList";
import { useSelector } from "react-redux";
import HeroList from "../heroStyles/heroList";

export default function Terms() {
  const { user, agent } = useSelector((state) => state.user.userData);
  const crmData = (agent || user)?.detail?.crmData;

  const terms = JSON.parse(crmData?.terms || "{}");

  console.log(terms);
  const customer = isCustomerSite();
  const ref = useRef();

  useEffect(() => {
    ref.current?.scrollIntoView({ behavior: "smooth" });
  }, []);

  return (
    <div ref={ref} className="mx-auto">
      <HeroList 
        title="Terms of Service" 
        overrideImage={terms.heroImage || "https://bebconsultancy.co.uk/wp-content/uploads/2019/09/Website-Terms-and-Conditions-Guide-1.jpeg"} 
      />

      <div className="flex flex-col items-center">
        <div className="pd-md max-w-full pb-10 w-full">
          <div className="mb-8">
            <p className="text-2xl font-bold mb-4">{terms.title || "Terms of Service"}</p>
            <div dangerouslySetInnerHTML={{ __html: terms.title || "" }} className="text-gray-700" />
          </div>

          {/* Fetching only the first index of the sections array */}
          {terms.sections && terms.sections.length > 0 && (
            <div className="mb-8">
              <p className="text-2xl font-bold mb-4">{terms.sections[0] || "Section Title"}</p>
              <div dangerouslySetInnerHTML={{ __html: terms.sections[0] || "" }} className="text-gray-700" />
            </div>
          )}

          {(terms.faq || []).map((faq, index) => (
            <div key={index} className="mb-4">
              <h3 className="text-xl font-bold">{faq.question || "FAQ Question"}</h3>
              <p className="text-gray-700">{faq.answer || "FAQ Answer"}</p>
            </div>
          ))}
        </div>
      </div>
      <FooterList />
    </div>
  );
}
