import React, { createContext, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { clone } from "lodash";
import { crmData } from "../../../../data/customer/CRM";
import Icon from "../../../HOC/Icon";
import Modal1 from "../../../DIsplay/Modal/Modal1";
import Button1 from "../../../form/Button1";
import TextInput from "../../../form/TextInput";
import StarRating from '../Testimonial/StarRating'
import { FileUploader } from "react-drag-drop-files";
import updateProfile from "../../../../controllers/user/updateProfile";
import { setUser } from "../../../../redux/reducers/userSlice";
import { enqueueSnackbar } from "notistack";
import axios from "axios";
import CreateFlightOrder from "../../../../pages/Orders/Flight/CreateFlightOrder";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

const ModalContext = createContext(null);

var cancelTokenSource = null;

export default function () {
  const { user } = useSelector(state => state.user.userData);
    
  let { testimonial = { heroText: null, heroImage:null,testimonials: [] } } = clone(user?.detail?.crmData || crmData);
  // dbData.sections = dbData?.sections?.map(obj => ({...obj,image: undefined,savedImage: obj?.image}))
  const defData = clone({ testimonial });
  const [data, setData] = useState(defData);
  const [modalOpen, setModalOpen] = useState({ heroText: null, heroImage:null,testimonials: null });
  const [loading, setLoading] = useState({ submit: false });
  const dispatch = useDispatch();

  const [rating, setRating] = useState(0);

  const addTestimonial = (newTestimonial) => {
    setData(prevData => ({
        ...prevData,
        testimonial: {
            ...prevData.testimonial,
            testimonials: [...prevData.testimonial.testimonials, newTestimonial]
        }
    }));
};
const toggleModal = (modalKey, state) => {
  setModalOpen((prev) => ({ ...prev, [modalKey]: state }));
};


  const handleRatingChange = (newRating) => {
    setRating(newRating);
    console.log('Selected rating:', newRating); // Or use it as needed
  };

  async function handleUpload(ev, newData) {
    ev?.preventDefault();
    // return console.log('payload: ',data)
    // if(bg?.file) {
    //     const formData = new FormData();
    //     formData.append('crmData.aboutUs.backgroundImage',bg?.file);
    //     const res = await updateProfile(formData)
    //     if(res.return) {
    //         enqueueSnackbar('Image Uploaded',{variant: 'success'})
    //     } else enqueueSnackbar(res.msg,{variant: 'error'})
    // }

    if (cancelTokenSource)
      cancelTokenSource.cancel("Canceled for another request");
    cancelTokenSource = axios.CancelToken.source();

    setLoading({ ...loading, submit: true });
    const res = await updateProfile(
      {
        crmData: {
          ...(user?.detail?.crmData || {}),
          ...(newData || data || {}),
        },
      },
      cancelTokenSource?.token
    );
    setLoading({ ...loading, submit: false });
    console.log(res);
    if (res.return) {
      enqueueSnackbar("Page Updated", { variant: "success" });
      console.log(res.data);
      dispatch(setUser(res.data));
    } else enqueueSnackbar(res.msg, { variant: "error" });
  }

  function handleRemoveHeroImage(i) {
    try {
      const temp = clone(data);
      console.log(temp);
      temp.terms.heroImage = temp.terms.heroImage.filter((_, ind) => ind !== i);
      setData(temp);
      handleUpload(null, temp);
    } catch (ex) {
      console.log(ex);
    }
  }

  const ReviewCard = ({ name, title, rating, review, onDelete, imageSrc }) => (
    <div className="relative w-68 p-3 bg-primary/5 border border-gray-200 rounded-lg shadow-sm">
      <div className="flex items-center gap-2">
        <img src={imageSrc} alt="Profile" className="w-10 h-10 rounded-full" />
        <div className="flex-1 min-w-0">
          <h3 className="font-semibold text-sm truncate">{name}</h3>
          <div className="flex">
            {Array.from({ length: 5 }, (_, i) => (
              <span
                key={i}
                className={`text-xs ${
                  i < rating ? "text-yellow-400" : "text-gray-300"
                }`}
              >
                ★
              </span>
            ))}
          </div>
        </div>
        <button
          className="absolute top-1 right-1 text-red-500"
          onClick={onDelete}
        >
          <Icon icon="material-symbols:delete" className="w-2 h-2" />
        </button>
      </div>
      <div className="mt-2 text-gray-600 text-xs line-clamp-3">
        <p className="font-bold text-xm text-black mb-2">{title}</p>
        <p>{review}</p>
      </div>
    </div>
  );

  const EditModal = ({ children, title, ...props }) => {
    const [value, setValue] = useState("");

    const handleSubmit = () => {
        const updData = { ...data, ...value };
        setData(updData);
        handleUpload(null, updData);
        props?.setOpen && props?.setOpen(false);
    };

    const handleAddTestimonial = () => {
        const newTestimonial = {
            clientName: value.clientName,
            rating: value.rating,
            review: value.review,
            title: value.title,
            profileImage: value.profileImage // Add this if you have an image upload feature
        };

        setData((prevData) => ({
            ...prevData,
            testimonial: {
                ...prevData.testimonial,
                testimonials: [
                    ...(prevData.testimonial.testimonials || []), 
                    newTestimonial
                ]
            }
        }));

        // Optionally, you can close the modal after adding
        props?.setOpen && props?.setOpen(false);
    };

    return (
        <Modal1 {...props}>
            <div className="p-5 flex flex-col gap-4">
                <div className="flex gap-4 justify-between py-4">
                    <b>{title}</b>
                    <Icon icon="mdi:close" onClick={() => props?.setOpen()} />
                </div>
                <ModalContext.Provider value={{ value, setValue }}>
                    {children}
                </ModalContext.Provider>
                <div className="flex gap-4">
                    <div>
                        <Button1 variant="text" onClick={() => props?.setOpen()}>
                            Close
                        </Button1>
                    </div>
                    <Button1 onClick={handleAddTestimonial}>Save</Button1>
                </div>
            </div>
        </Modal1>
    );
};


  let parsedData = {};
  try {
    parsedData = JSON.parse(data?.terms || "{}");
  } catch (ex) {}

  function handleRemoveSection(i) {
    try {
      const temp = { ...parsedData };
      temp.sections = temp?.sections?.filter((_, ind) => ind !== i);
      const newData = { terms: JSON.stringify(temp) };
      setData(newData);
      handleUpload(null, newData);
    } catch (ex) {}
  }

  const demoData = {
    testimonials: [
      {
        id: 1,
        name: "Dimma Akinjide",
        rating: 5,
        title: "Excellent Service",
        comment:
          "Top notch service. Lorem ipsum dolor sit amet consectetur. Lectus viverra viverra ultrices amet diam vel pellentesque. At cursus ut ornat libero morbi consectetur aliquam.",
        imageUrl:
          "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSub0wr96lOiopPtXAYOGwOMbhXZFxbrxKrUg&s",
      },
      {
        id: 2,
        name: "John Doe",
        rating: 4,
        title: "Great Experience",
        comment:
          "Great experience overall, I would definitely come back. Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
        imageUrl:
          "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTuvcq5fPdC59b5n1rRWJUppMh5fiOINKy00Q&s",
      },
      {
        id: 3,
        name: "Jane Smith",
        rating: 5,
        title: "Outstanding Quality",
        comment:
          "Exceptional quality and customer service. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor.",
        imageUrl:
          "https://www.hollywoodreporter.com/wp-content/uploads/2019/07/jav519a_0214b-h_2019.jpg?w=1296&h=730&crop=1",
      },
    ],
  };

  const sections = [
    {
      title: "Hero Section",
      section: [
        {
          label: "Hero Text",
          body: crmData?.hero?.text || "No text available",
          action: (section) => (
            <div>
              <button
                onClick={() => toggleModal('title', true)}
                className="text-xs flex gap-2 bg-primary/5 p-2 px-4"
              >
                Change <Icon icon="tabler:edit" className="!w-4 !h-4" />
              </button>
              <EditModal
                open={modalOpen.title}
                setOpen={() => toggleModal('title', false)}
                title={section.title}
              >
                <ModalContext.Consumer>
                  {(obj) => {
                    const { value, setValue } = obj || {};
                    return (
                      <div>
                        <TextInput
                          label={"Title Text"}
                          value={value.title || ""}
                          onChange={(ev) =>
                            setValue({ title: ev.target.value })
                          }
                        />
                      </div>
                    );
                  }}
                </ModalContext.Consumer>
              </EditModal>
            </div>
          ),
        },
        {
          label: 'Hero Image',
          body: <ImageListComponent handleDelete={(i) => handleRemoveHeroImage(i)} data={data?.hero?.images} />,
          action: (section) => (
            <div>
              <button 
                onClick={() => toggleModal('heroImage', true)}
                className="text-xs flex gap-2 bg-primary/5 p-2 px-4"
              >
                Add <Icon icon='material-symbols:add' className='!w-4 !h-4' />
              </button>
              <EditModal 
                open={modalOpen.heroImage} 
                setOpen={() => toggleModal('heroImage', false)} 
                title={section.title}
              >
                <ModalContext.Consumer>
                  {(obj) => {
                    const { value, setValue } = obj || {};
                    const handleBG = (file) => {
                      // Handle file reading and setting the image
                    };
                    return (
                      <div>
                        <FileUploader
                          handleChange={handleBG}
                          name={'files'}
                          types={["JPG", "JPEG", "PNG", "GIF"]}
                        >
                          {/* File upload interface */}
                        </FileUploader>
                      </div>
                    );
                  }}
                </ModalContext.Consumer>
              </EditModal>
            </div>
          ),
        }
      ],
    },
    {
      title: "Testimonials",
      section: [
        {
          label: "List",
          body: (
            <div className="container mx-auto px-4">
              <div className="grid grid-cols-1 md:grid-cols-2 gap-4 ">
                {crmData?.testimonial?.testimonials?.map((testimonial, index) => (
                  <ReviewCard
                    key={index}
                    name={testimonial?.clientName || "Anonymous"}
                    rating={testimonial?.rating || 5}
                    review={testimonial?.review || "No review provided."}
                    imageSrc={testimonial?.profileImage || ""}
                    title={testimonial?.title || ""}
                    onDelete={() => handleRemoveSection(index)}
                  />
                ))}
              </div>
            </div>
          ),
          action: (section) => (
            <div>
              <button
                onClick={() => toggleModal('quickInfo', true)}
                className="text-xs flex gap-2 bg-primary/5 p-2 px-4"
              >
                Add Review <Icon icon="material-symbols:add" className="w-4 h-4" />
              </button>
              <EditModal
                open={modalOpen.quickInfo}
                setOpen={() => toggleModal('quickInfo', false)}
                title={section.title}
              >
                <ModalContext.Consumer>
                  {(obj) => {
                    const { value, setValue } = obj || {};
                    return (
                      <div className="flex flex-col gap-4 w-[500px]">
                        <TextInput
                          label="Client Name"
                          value={value.clientName || ""}
                          onChange={(ev) =>
                            setValue({ clientName: ev.target.value })
                          }
                          type="text"
                        />
                        <label htmlFor="rating" className=" font-small">Rating</label>
                        <StarRating
                          initialRating={value?.rating || 0}
                          onChange={(newRating) => setValue({ rating: newRating })}
                        />
                        <TextInput
                          label="Short Comment"
                          value={value.title || ""}
                          onChange={(ev) => setValue({ title: ev.target.value })}
                          type="text"
                        />
                        <label htmlFor="review" className=" font-small">Review</label>
                        <textarea
                          id="review"
                          rows="6"
                          className="p-2 border border-gray-300 rounded-md"
                          value={value.review || ""}
                          onChange={(ev) => setValue({ review: ev.target.value })}
                        />
                        
                      </div>
                    );
                  }}
                </ModalContext.Consumer>
              </EditModal>
            </div>
          ),
        },
      ],
    },
  ];
  

  let currentHost = window.location.protocol + "//" + window.location.host;
  const iframeRefScale = useRef();
  const [scale, setScale] = useState(0.3);

  const updateScale = () => {
    let screenWidth = window.innerWidth;
    if (iframeRefScale.current) {
      setScale(iframeRefScale.current.offsetWidth / screenWidth);
    }
  };
  useEffect(() => {
    window.addEventListener("resize", updateScale);
    updateScale();
    return () => {
      window.removeEventListener("resize", updateScale);
    };
  }, []);

  return (
    <div className="pd-md flex gap-4 flex-wrap md:flex-nowrap overflow-hidden">
      <div className="md:w-[65%]">
        <div className="pb-4">
          <h3>Edit Testimonial Page Content</h3>
          <p>
            These logo, colors, and font will be used to design your site. You
            can always change them later.
          </p>
        </div>
        <div className="flex flex-col gap-4">
          {sections?.map((section, i) => (
            <div>
              <div className="bg-theme1/10 p-4 py-2">{section?.title}</div>
              {section?.section?.map((obj, j) => (
                <div
                  key={section?.title + obj?.label + j}
                  className="flex gap-4 p-4 border-b"
                >
                  <div className="w-[150px]">{obj?.label}</div>
                  <div className="flex-1 max-w-[64%] overflow-hidden">
                    {obj?.body}
                  </div>
                  <div>{obj?.action(section)}</div>
                </div>
              ))}
            </div>
          ))}
        </div>
      </div>
      <div className="origin-top-left md:w-[30%]">
        <div className="flex justify-center mb-6">
          <button
            className="text-xs flex gap-2 bg-primary/5 p-2 px-4"
            onClick={() =>
              window.open(
                currentHost + "/preview/template/testimonial",
                "_blank"
              )
            }
          >
            Preview
          </button>
        </div>
        <iframe
          src={currentHost + "/preview/template/testimonial"}
          className="w-screen origin-top-left overflow-hidden min-h-[70vh]"
          style={{ height: `${100 / scale}%`, transform: `scale(${scale})` }}
        />
      </div>
    </div>
  );
}

function getImageSource(val) {
  if ((val || "")?.startsWith("http")) return val;
  else return "data:image/jpeg;base64," + val;
}

function ImageListComponent({ data, handleDelete, imageKey, render }) {
  if (!data?.length) return null;

  return (
    <div className="flex gap-4 max-w-full flex-wrap">
      {data?.map((obj, i) => (
        <div key={i}>
          <div className="w-40 relative">
            <div
              className="absolute z-10 top-0 right-0 p-1 rounded-md m-2 bg-red-200 cursor-pointer"
              onClick={() => handleDelete && handleDelete(i)}
            >
              <Icon
                icon="material-symbols:delete-outline"
                className="text-red-500 !w-5 !h-5"
              />
            </div>
            {!render ? (
              <img
                src={getImageSource(imageKey ? obj[imageKey] : obj)}
                className="w-full h-32 object-cover"
              />
            ) : (
              render(obj, i)
            )}
          </div>
        </div>
      ))}
    </div>
  );
}

function TextListComponent({ data, render, handleDelete }) {
  return (
    <div className="flex gap-4 max-w-full flex-wrap">
      {data?.map((val, i) => (
        <div key={i}>
          <div className="px-3 py-1 bg-primary/20 rounded-full flex items-center capitalize gap-2 relative">
            {render ? render(val, i) : val}
            <div
              className="rounded-md  cursor-pointer"
              onClick={() => handleDelete && handleDelete(i)}
            >
              <Icon icon="mdi:close" className="!w-4 !h-4" />
            </div>
          </div>
        </div>
      ))}
    </div>
  );
}
