import { useSelector } from "react-redux";

export const Contact = () => {
    const {user} = useSelector(state => state.user.userData);
    const contact = user?.detail?.contact;

    return (
      <div className="w-full flex flex-col mt-6">
        <span className="font-bold text-xl mb-3">Contact</span>
        <div className="flex w-full flex-col shadow-md mb-6">
          <div className="flex flex-col w-full shadow-md">
            <div className="p-4 bg-white flex flex-wrap gap-4">
              <div className="flex flex-1 flex-col gap-2">
                <span className="text-sm lg:text-base text-[#818083] ">Name</span>
                <span className=" text-sm">{contact?.firstName} {contact?.lastName}</span>
              </div>
              <div className="flex flex-1 flex-col gap-2">
                <span className="text-sm lg:text-base text-[#818083] ">
                  E-mail
                </span>
                <span className="text-sm">{contact?.email}</span>
              </div>
              <div className="flex flex-1 flex-col gap-2 lg:items-center">
                <span className="text-sm lg:text-base text-[#818083] ">
                  Phone no
                </span>
                <span className="text-sm">{contact?.phoneNumber}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };
  