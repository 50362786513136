import React from "react";
import GenerateLinkForm from "../generateForm";

const GenerateLinkHome = () => {
  return (
    <div className="mx-auto p-6">
      <div>
        <h2 className="font-BrFirmaRegular text-2xl my-0">
          {" "}
          Generate your website theme
        </h2>
        <p className="font-BrFirmaRegular my-2">
          Upload your logo and choose your brand color for your new website.
        </p>
      </div>
      <GenerateLinkForm />
    </div>
  );
};

export default GenerateLinkHome;
