import React from 'react';
import FooterList from '../footers/footerList';
import HeroList from '../heroStyles/heroList';

const Testimonial = ({ crmData }) => {
  // Demo data
  const demoData = {
    testimonials: [
      {
        id: 1,
        name: "Dimma Akinjide",
        rating: 5,
        title: "Excellent Service",
        comment: "Top notch service. Lorem ipsum dolor sit amet consectetur. Lectus viverra viverra ultrices amet diam vel pellentesque. At cursus ut ornat libero morbi consectetur aliquam.",
        imageUrl: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSub0wr96lOiopPtXAYOGwOMbhXZFxbrxKrUg&s"
      },
      {
        id: 2,
        name: "John Doe",
        rating: 4,
        title: "Great Experience",
        comment: "Great experience overall, I would definitely come back. Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
        imageUrl: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTuvcq5fPdC59b5n1rRWJUppMh5fiOINKy00Q&s"
      },
      {
        id: 3,
        name: "Jane Smith",
        rating: 5,
        title: "Outstanding Quality",
        comment: "Exceptional quality and customer service. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor.",
        imageUrl: "https://www.hollywoodreporter.com/wp-content/uploads/2019/07/jav519a_0214b-h_2019.jpg?w=1296&h=730&crop=1"
      },
    ],
    heroText: "What Our Clients Say",
    heroImage: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSe-E0ZiMt_P_sHWaEBn8L5E-gR8bMhe8nlIA&s"
  };

  const Hero = ({ heroText, heroImage }) => {
    const defaultImage = "";

    return (
      <div className="relative max-w-7xl mx-auto rounded-lg overflow-hidden shadow-lg my-8">
        <div
          className="relative h-96 md:h-[500px] w-full bg-cover bg-center flex items-center"
          style={{
            backgroundImage: `url(${heroImage || defaultImage})`,
          }}
        >
          <div className="absolute inset-0 bg-black opacity-50"></div>
          <div className="relative z-10 p-8 bg-white rounded-lg shadow-lg max-w-md flex flex-col justify-center lg:ml-6 sm:ml-2">
            <div className="uppercase tracking-wide text-sm text-blue-400 font-semibold">
              Testimonials
            </div>
            <h1 className="mt-1 text-3xl md:text-4xl font-bold leading-tight text-gray-900">
              {heroText || "What Our Clients Say"}
            </h1>
            <p className="mt-2 text-gray-600">
              Hear from our satisfied customers
            </p>
          </div>
        </div>
      </div>
    );
  };

  const testimonials = crmData?.testimonials || demoData.testimonials;
  const heroText = crmData?.heroText || demoData.heroText;
  const heroImage = crmData?.heroImage || demoData.heroImage;

  return (
    <div className="mx-auto mb-10">
      <HeroList title="Testimonials" overrideImage={heroImage || "https://media.istockphoto.com/id/583724238/photo/summertime-fun.jpg?s=612x612&w=0&k=20&c=XOe4pBepfLYRO2wOIB3suTi9ARqXNE3XoKe70bcZAKo="} />
      <div className="py-8">
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
          {testimonials.map((testimonial) => (
            <div key={testimonial.id} className="bg-white rounded-lg shadow-md p-6">
              <div className="flex items-center mb-4">
                <img 
                  src={testimonial.imageUrl} 
                  alt={testimonial.name} 
                  className="w-12 h-12 rounded-full object-cover mr-4"
                />
                <div>
                  <h3 className="font-semibold text-lg">{testimonial.name}</h3>
                  <div className="flex">
                    {[...Array(5)].map((_, index) => (
                      <span key={index} className={`text-sm ${index < testimonial.rating ? "text-yellow-400" : "text-gray-300"}`}>
                        ★
                      </span>
                    ))}
                  </div>
                </div>
              </div>
              <p className="font-bold text-black mb-2">{testimonial.title}</p>
              <p className="text-gray-600 text-sm">{testimonial.comment}</p>
            </div>
          ))}
        </div>
      </div>
      <FooterList />
    </div>
  );
};

export default Testimonial;