import React from 'react'
import About from './Layout/About';
import About2 from './Layout/About2';
import { useSelector } from 'react-redux';
import About3 from './Layout/About3';
import About4 from './Layout/About4';
import About5 from './Layout/About5';

export default function AboutList({id,noScroll}) {
    const state = useSelector((state)=> state.template);  
    console.log(state.aboutLayout)
    
    switch (id || state.aboutLayout) {
        case 1 :
            return <About5 noScroll={noScroll} />
        case 2:
            return <About2 noScroll={noScroll} />
        case 3:
            return <About3 noScroll={noScroll} />
        case 4:
            return <About4 noScroll={noScroll} />
        default:
            return <About noScroll={noScroll} />
    }
}
