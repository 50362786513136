import React, { useEffect, useRef, useState } from "react";
import { Radio } from "@mui/material";
import SearchBarList from "../../../../components/templates/Searchbars/searchBarList";
import { useDispatch, useSelector } from "react-redux";
import { setSearchbarStyle } from "../../../../redux/reducers/template/templateSlice";


const SearchBarStyle = () => {
  const state = useSelector((state) => state.template);
  const [scale, setScale] = useState(0.4);
  const iframeRefScale = useRef(null);
  const dispatch = useDispatch();
  let curHeight = window.innerHeight;
  const updateScale = () => {
      let screenWidth = window.innerWidth;
      if (iframeRefScale.current) {
        setScale((iframeRefScale.current.offsetWidth / screenWidth));
      }
  }
  const updateSearchBarStyle = (value) => {
    dispatch(setSearchbarStyle(value))
  };
  useEffect(()=>{
    window.addEventListener('resize', updateScale)
    updateScale()
    return ()=>{
      window.removeEventListener('resize', updateScale)
    }
  },[])
  return (
    <div className="p-6 mx-auto col-span-3">
      <div>
        <h1 className="font-BrFirmaRegular text-2xl my-0">
          {" "}
          Select a search bar style
        </h1>
        <p className="font-BrFirmaRegular my-2">
          Choose a search bar for your website.
        </p>
      </div>
      <div className="mt-10 overflow-hidden" ref={iframeRefScale}>
        <span className="font-BrFirmaRegular font-bold">
          {" "}
          Select header style
        </span>
        <div className="grid grid-flow-row gap-4 mt-4">
        {Array.from({ length: 5 }, (_, index) => {
          const id = index + 1;
          return(
            <div
              className={`cursor-pointer ${
                state.searchbarStyle == String(id)
                  ? "bg-[#C4E9E3]"
                  : "bg-[#F8F8F8]"
              } p-2 rounded-md shadow-sm`}
              onClick={() => updateSearchBarStyle(String(id))}
            >
              <Radio
                size="small"
                color="success"
                checked={state.searchbarStyle == String(id) ? true : false}
              />
              <span className="mt-2 font-poppins text-sm font-bold text-[#2E2E32]">
                Search bar {id}
              </span>
              <div className="mt-2 origin-top-left w-screen" style={{transform: `scale(${scale})`}}>
                <div className="pr-10">
                  <SearchBarList id={String(id)}/>
                </div>
              </div>
            </div>)
        })}
        </div>
      </div>
    </div>
  );
};

export default SearchBarStyle;