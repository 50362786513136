import { useSelector } from 'react-redux';
import { getsubDomain } from '../../../utils/getsubDomain';
import { useLocation } from 'react-router-dom';

const Hero2 = ({ overrideImage, title }) => {
  const state = useSelector((state) => state.template);
  const { userData: { user, loggedIn } } = useSelector((state) => state.user);
  const agency = getsubDomain();
  const location = useLocation();
  const pathName = location.pathname;
  
  let activeLink = 'flight';
  if (pathName?.includes('accommodation')) activeLink = 'stays';
  else if (pathName?.includes('tours')) activeLink = 'tours';

  return (
    <div className="relative flex items-end justify-start text-white min-h-[400px] pd-10">
      {overrideImage && (
        <img
          src={overrideImage}
          alt="Hero Background"
          className="absolute left-0 right-0 w-full h-full object-cover"
        />
      )}
      {/* Overlay for the background image */}
      <div className="absolute top-0 left-0 w-full h-full bg-black/25"></div>
      
      {/* Text container with bottom-left alignment */}
      <div className="relative z-10 p-6 md:p-10">
        <h1 className="capitalize text-3xl md:text-4xl font-bold !text-white ">{title}</h1>
      </div>
    </div>
  );
};

export default Hero2;
