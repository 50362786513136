import { useSelector } from "react-redux";
import { Layout1 } from "./Layout1";
import { Layout2 } from "./Layout2";
import { Layout3 } from "./Layout3";
import { Layout4 } from "./Layout4";
import { Layout5 } from "./Layout5";

// Object mapping layout numbers to their components
const layoutComponents = {
  1: Layout1,
  2: Layout2,
  3: Layout3,
  4: Layout4,
  5: Layout5,
};

export function CustomerHome() {
  const state = useSelector((state) => state.template);
  const layoutType = Number(state.layoutType);

  console.log('layout: ', layoutType);

  // Get the corresponding layout component or default to Layout4
  const SelectedLayout = layoutComponents[layoutType] || Layout4;

  return <SelectedLayout />;
}