import { useSelector } from "react-redux";
import SearchBarList from "../Searchbars/searchBarList";
import FooterList from "../footers/footerList";
import HomeCarousel from "../layouts/HomeCarousel";
import HeadersList from "../headers/headersList";

const demoDestinations = [
  { name: "Paris", image: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR73oz60tYFhaapkWemQ7oggHztiDsuxBoLDQ&s", location: "Paris, France" },
  { name: "Moscow", image: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSWMkrdvzZJ5zeeXljbPI5h4d21FMuwVeUsHA&s", location: "Moscow, Russia" },
  { name: "London", image: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTNLhqqEOOBzvZ44xKRuDR_bVEtpsMLWGh78Q&s", location: "London, UK" },
  { name: "Mexico City", image: "https://upload.wikimedia.org/wikipedia/commons/thumb/4/4f/Sobrevuelos_CDMX_HJ2A4913_%2825514321687%29_%28cropped%29.jpg/1200px-Sobrevuelos_CDMX_HJ2A4913_%2825514321687%29_%28cropped%29.jpg", location: "Mexico City, Mexico" },
  { name: "Paris", image: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR73oz60tYFhaapkWemQ7oggHztiDsuxBoLDQ&s", location: "Paris, France" },
  { name: "Moscow", image: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSWMkrdvzZJ5zeeXljbPI5h4d21FMuwVeUsHA&s", location: "Moscow, Russia" },
  { name: "London", image: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTNLhqqEOOBzvZ44xKRuDR_bVEtpsMLWGh78Q&s", location: "London, UK" },
];

export function Tours() {
    const state = useSelector((state) => state.template);
    const { user, agent } = useSelector(state => state.user.userData);
    const crmData = (agent || user)?.detail?.crmData;

    return (
        <div className="w-full h-auto">
            <HeadersList/>
            {/* Banner Section */}
            <div className="bg-[#EBEFF9] flex flex-col gap-10 items-left pb-10 relative">
                <HomeCarousel data={crmData?.hero?.images} />
                <div className="absolute top-0 left-0 w-full h-full bg-black/20"></div>
                <div className="origin-top-left z-10">
                    <div className="font-bold px-6 md:px-20 h-auto text-2xl origin-left mt-20">
                        {!crmData?.hero?.text ? 
                        <span className="flex flex-col gap-2">
                            <span style={{color: state.secondaryColor}}>{'Hello there'},</span>
                            Book cheap flights with your one-step travel shop!
                        </span> :
                        <div dangerouslySetInnerHTML={{ __html: crmData?.hero?.text }} />
                        }
                    </div>
                </div>
                <div className="flex justify-center origin-top-left px-6 md:px-20 z-10">
                    <div className="!w-full origin-top-left">
                        <SearchBarList />
                    </div>
                </div>
            </div>

            {/* Top Destinations Section */}
            <div className="container mx-auto px-4 my-8">
                <h2 className="text-2xl font-semibold mb-6 text-gray-800">Top destinations from your location</h2>
                
                <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6">
    {(crmData?.topDestinations || demoDestinations).map((dest, index) => (
        <div key={index} className="relative bg-white rounded-lg overflow-hidden shadow-md hover:shadow-lg transition-shadow duration-300">
            <img
                src={dest.image}
                alt={dest.name}
                className="w-full h-48 object-cover"
            />
            
            <div className="p-2">
                <h3 className="font-semibold text-lg text-gray-900 mb-2">{dest.name}</h3>
                <p className="text-sm text-gray-600 flex items-center">
                    <svg className="w-4 h-4 mr-1 text-blue-500" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z"></path>
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M15 11a3 3 0 11-6 0 3 3 0 016 0z"></path>
                    </svg>
                    {dest.location}
                </p>
            </div>
        </div>
    ))}
</div>
            </div>


            {/* Footer */}
            <FooterList />
        </div>
    );
}
